import React from 'react'
import { useListContext } from 'react-admin'
import makeStyles from '@mui/styles/makeStyles'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { Button, Toolbar } from '@mui/material'

const useListPaginationStyles = makeStyles({
    paginationBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        border: 'none',
        '& .numbers': {
            lineHeight: '28px',
            paddingRight: '10px',
            color: 'rgba(0,0,0,0.6)',
        },
        '& > button': {
            minWidth: '36px',
            width: '36px',
            height: '36px',
            margin: '2.5px',
            '& > span': {
                '& > svg': {
                    fontSize: '2.3rem',
                },
            },
        },
    },
})

export default () => {
    const { page, perPage, setPage, data } = useListContext()
    const displayCount = Object.entries(data).length
    // because we don't know how many records are in the database, we will assume that that
    // number is not a multiple of the 'per page' value. This creates an edge case bug.
    // If the total number of records is evenly divisible the per page value, it will appear
    // that there are more records when there are not.
    const isProbablyTheEnd = displayCount !== perPage
    const classes = useListPaginationStyles()
    const first = (page - 1) * perPage + 1
    const last = first + displayCount - 1
    return (
        displayCount > 0 && (
            <Toolbar className={classes.paginationBar}>
                <span className={'numbers'}>
                    {first}-{last} of {isProbablyTheEnd ? last : 'many'}
                </span>
                <Button
                    color="primary"
                    aria-label="prev"
                    variant="outlined"
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                >
                    <KeyboardArrowLeft />
                </Button>
                <Button
                    color="primary"
                    aria-label="next"
                    variant="outlined"
                    onClick={() => setPage(page + 1)}
                    disabled={isProbablyTheEnd}
                >
                    <KeyboardArrowRight />
                </Button>
            </Toolbar>
        )
    )
}
