import { createContext, useContext } from 'react'
import queryString from 'query-string'
import { mapKeys, camelCase } from 'lodash-es'

export const POPUP_WINDOW_PARAMS = `width=600,height=800,left=100,top=100,scrollbars,resizable`

export const keycloakURIFromHost = () => {
    const ourHostName = window.location.hostname
    const baseHost = ourHostName.split('.').slice(1).join('.')

    if (
        window.location !== window.parent.location ||
        window.location.hostname.startsWith('emp.')
    ) {
        // The page is in an iFrames or is an EMP route
        return `https://authz.nc.${baseHost}/auth`
    } else {
        return `https://authz.${baseHost}/auth`
    }
}

export const apiURIFromHost = () => {
    const ourHostName = window.location.hostname
    const baseHost = ourHostName.split('.').slice(1).join('.')

    return `https://api.${baseHost}/v1`
}

export const keycloakCustomerRealmFromHost = () => {
    // if (overrideRealm)
    //     return overrideRealm;
    // <account_name>/...
    const pathParts = window.location.pathname.split('/')
    const account = pathParts[1]
    if (window.location.hostname.includes('pandium.io'))
        return `prod-${account}`
    else if (window.location.hostname.includes('alpha'))
        return process.env.REACT_APP_OVERRIDE_REALM
    else return `staging-${account}`
}

export const getTheme = async (keycloak_token) => {
    const generatedTheme = await fetchTheme(keycloak_token)

    if (generatedTheme !== '') {
        const themeConfig = JSON.parse(generatedTheme)
        // convert the snake cased response from backend to camel case
        return mapKeys(themeConfig, (val, key) => {
            return camelCase(key)
        })
    }
}

export const getDeepLinkInfo = async (keycloak) => {
    return processQueryString(keycloak)
}

export const NAMESPACE = keycloakCustomerRealmFromHost()

export const KeycloakConfig = {
    realm: process.env.REACT_APP_OVERRIDE_REALM || NAMESPACE,
    url: process.env.REACT_APP_OVERRIDE_KEYCLOAK_URI || keycloakURIFromHost(),
    clientId: process.env.REACT_APP_OVERRIDE_CLIENT_ID || 'imp-frontend',
}
export const NO_COOKIE = KeycloakConfig.url.includes('.nc.').toString()
export const API_URI = process.env.REACT_APP_API_URI || apiURIFromHost()

export const GA_DEBUG = false
export const GA_CODE = ''

export const HEADERS = (token) => {
    return {
        'X-NAMESPACE': process.env.REACT_APP_OVERRIDE_REALM || NAMESPACE,
        'no-cookie': NO_COOKIE,
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
    }
}

// TODO here we are assuming only one theme per namespace, will need to fix when we have multiple themes
const fetchTheme = (keycloak_token) => {
    return fetch(`${API_URI}/themeconfigs`, {
        method: 'GET',
        headers: HEADERS(keycloak_token),
    })
        .then((response) => {
            return response.json()
        })
        .then((myJson) => {
            return JSON.stringify(myJson[0])
        })
}

const processQueryString = (keycloak) => {
    let deepLink = null
    const qs = queryString.parse(window.location.search)
    const ps = qs['ps']
    const integration_id = qs['integration_id']
    const user = [
        keycloak.tokenParsed.username,
        keycloak.tokenParsed.aid,
    ].filter((token) => token) // remove null/undefined values
    if (ps) {
        deepLink = 'TENANT'
        // TODO talk about url routes
        return fetch(
            `${API_URI.replace('v1', 'v0')}/author/deep_link/tenant?ps=${ps}`,
            {
                method: 'GET',
                headers: HEADERS(keycloak.token),
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                return { deepLink, id: data }
            })
    } else if (integration_id) {
        deepLink = 'INTEGRATION'
        // check if this integration has tenants
        return fetch(
            `${API_URI.replace(
                'v0',
                'v1'
            )}/tenants?connected_users__in=${encodeURIComponent(
                JSON.stringify(user)
            )}&integration_id=${integration_id}`,
            {
                method: 'GET',
                headers: HEADERS(keycloak.token),
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.length === 0) {
                    // no tenants
                    return { deepLink: 'TENANT-CREATE', id: integration_id }
                }
                // has tenants, needs to redirect to a tenants list filetered by integration_id
                return { deepLink, id: integration_id }
            })
    } else {
        return null
    }
}

export const ThemeConfigContext = createContext()
export const useThemeConfig = () => useContext(ThemeConfigContext)
export const ThemeConfigContextProvider = ({ value, children }) => (
    <ThemeConfigContext.Provider value={value}>
        {children}
    </ThemeConfigContext.Provider>
)
