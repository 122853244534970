import React from 'react'
import { SelectInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'

const DEFAULT_SCHEDULES = [
    { id: '*/30 * * * *', name: 'Every 30 minutes' },
    { id: '5 */1 * * *', name: 'Every 1 Hour' },
    { id: '10 */4 * * *', name: 'Every 4 Hours' },
    { id: '15 */6 * * *', name: 'Every 6 Hours' },
    { id: '20 */12 * * *', name: 'Every 12 Hours' },
    { id: '0 1 * * *', name: 'Once per day' },
]

/**
 *
 * @param pauseInDropdown - will add 'paused' option if true
 * @param integrationScheduleList - integration-specific options to
 * override defaults
 * @returns {*}
 * @constructor
 */
export default ({ pauseInDropdown, integrationScheduleList }) => {
    const form = useFormContext()

    const scheduleOptions =
        integrationScheduleList?.map((schedule) => {
            return { id: schedule.cron, name: schedule.schedule }
        }) ?? DEFAULT_SCHEDULES

    if (pauseInDropdown) {
        const values = form.getValues()
        if (values.paused) {
            form.setValue('userSchedule', 'paused')
        }
        if (!scheduleOptions.some((option) => option.id === 'paused')) {
            scheduleOptions.push({ id: 'paused', name: 'paused' })
        }
    }

    return (
        <SelectInput
            className={'pandium-dropdown'}
            emptyText="Select option"
            emptyValue=""
            source="userSchedule"
            choices={scheduleOptions}
            sx={{
                width: '256px',
                '& > div.MuiInputBase-root > div': {
                    padding: '16px 12px 10px',
                },
            }}
            onChange={(ev) => {
                if (pauseInDropdown) {
                    form.setValue('paused', ev.target.value === 'paused')
                }
            }}
            label="Select"
            variant="outlined"
            inputProps={{ 'aria-label': 'Without label' }}
        />
    )
}
