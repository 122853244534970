import React from 'react'
import './index.css'
import App from './App'
import Keycloak from 'keycloak-js'
import * as Sentry from '@sentry/browser'
import * as config from './themeConfig'
import ErrorPage from './components/ErrorPage'
import { createRoot } from 'react-dom/client'

Sentry.init({
    // imp project
    dsn: 'https://a4f0d3f83cae4f4099308e8639968f8e@sentry.io/1825388',
    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.NODE_ENV,
})

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { error: null }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error })
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key])
            })
            Sentry.captureException(error)
        })
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return <ErrorPage />
        } else {
            //when there's not an error, render children untouched
            return this.props.children
        }
    }
}

const keycloak = new Keycloak(config.KeycloakConfig)
// TODO Is this still true?
//We have to init the keycloak instance before we mount any components.
// Only way to use the javascript adapter and the authClient
// Also look 'ma' they rolled their own promises... smh. Will re-wrap.
keycloak
    .init({ onLoad: 'login-required', checkLoginIframe: false })
    .then(async () => {
        sessionStorage.setItem('token', keycloak.token)
        const namespace = config.NAMESPACE
        const theme = await config.getTheme(keycloak.token)
        const deepLinkInfo = await config.getDeepLinkInfo(keycloak)
        const root = createRoot(document.getElementById('root'))

        // Disable the scrollbar from rendering for dynamic resizing iframes
        if (theme?.configs?.marketplaceSettings?.hideScrollBar) {
            // Hides Scrolbar Firefox
            document.body.style.scrollbarWidth = 'none'
            // Hides Scrolbar IE and Edge
            document.body.style.msOverflowStyle = 'none'
            // Hides Scrollbar Chrome
            document.documentElement.style.scrollbarWidth = 'none'
        }

        root.render(
            <ErrorBoundary>
                <App
                    keycloak={keycloak}
                    namespace={namespace}
                    deepLinkInfo={deepLinkInfo}
                    theme={theme}
                />
            </ErrorBoundary>
        )
    })
    .catch(() => {
        //TODO: 'Will need a 500 "page"!'
        console.error('Woopsie')
    })

keycloak.onTokenExpired = () => {
    // Do not put a time here. It will match token expiration on keycloak.
    // the time here is minValidity. that is token refreshes if the current token validity is >=
    keycloak
        .updateToken()
        .then(() => {
            sessionStorage.setItem('token', keycloak.token)
        })
        .catch(() => {
            console.debug('Failed to refresh token')
        })
}

keycloak.onAuthRefreshSuccess = () => {
    sessionStorage.setItem('token', keycloak.token)
}
