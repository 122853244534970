import React from 'react'
import { RichTextField } from 'react-admin'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { addHook } from 'dompurify'
import { get } from 'lodash-es'

export const ContentBlock = (props) => {
    addHook('afterSanitizeAttributes', (node) => {
        // set all links to target=_blank
        if (node.tagName === 'A') {
            node.setAttribute('target', '_blank')
            node.setAttribute('rel', 'noopener')
        }
    })
    return (
        <RichTextField
            {...props}
            sx={{ overflow: 'hidden' }}
            className="pandium-text"
        />
    )
}

const useDisconnectDialogStyles = makeStyles({
    dialogContainer: {
        display: 'flex',
    },
    dialogContent: {
        width: '300px',
    },
    dialogDeleteButton: {
        color: '#fc3b40',
    },
})

export const DisconnectDialog = (props) => {
    const { open, handleClose, connector, disconnect } = props
    const classes = useDisconnectDialogStyles()

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs">
            <DialogTitle> {get(connector, 'label', connector.id)} </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                    Are you sure you want to disconnect?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    aria-label={'confirm-disconnect-button'}
                    onClick={() => {
                        disconnect()
                        handleClose()
                    }}
                    className={classes.dialogDeleteButton}
                >
                    Disconnect
                </Button>
                <Button onClick={handleClose}> Cancel </Button>
            </DialogActions>
        </Dialog>
    )
}
