import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Notification } from 'react-admin'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { useNavigate } from 'react-router-dom'

const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = useNavigate()
        return <Component history={history} {...props} />
    }
    return Wrapper
}

const classes = {
    appFrame: (theme) => ({
        display: 'flex',
        backgroundColor: theme.palette.background.default,
        overflow: 'hidden',
        justifyContent: theme.marketplaceSettings.justify || 'left',
    }),
    content: (theme) => ({
        margin: theme?.marketplaceSettings?.fullWidthMarketplace
            ? '0'
            : '64px 32px 0px',
        flex: theme?.marketplaceSettings?.fullWidthMarketplace ? '1' : 'none',
        [theme.breakpoints.up('lg')]: {
            margin: '64px 64px 0px',
        },
        boxSizing: 'border-box',
        width: '776px',
        [theme.breakpoints.up('sm')]: {
            width: '896px',
        },
        [theme.breakpoints.up('md')]: {
            width: '960px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '1120px',
        },
        [theme.breakpoints.up('xl')]: {
            width: '1280px',
        },
        [theme.breakpoints.up('xxl')]: {
            width: '1608px',
        },
        justifyContent: theme.marketplaceSettings.justify || 'left',
        display: 'flex',
    }),
    loader: {
        position: 'absolute',
        bottom: '20px',
        left: '70px',
        margin: 25,
        zIndex: 1200,
        width: '2em',
        height: '2em',
        color: 'white',
    },
}

class Layout extends Component {
    render() {
        const { children, isLoading = false } = this.props
        return (
            <Box sx={classes.appFrame}>
                <Box sx={classes.content}>{children}</Box>
                <Notification />
                {isLoading && (
                    <CircularProgress
                        className={classes.loader}
                        color={'inherit'}
                        thickness={8}
                    />
                )}
            </Box>
        )
    }
}

const EnhancedLayout = withRouter(Layout)

// Inject theme into layout
class LayoutWithTheme extends Component {
    render() {
        const { theme, ...rest } = this.props
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <EnhancedLayout {...rest} />
                </ThemeProvider>
            </StyledEngineProvider>
        )
    }
}

LayoutWithTheme.propTypes = {
    theme: PropTypes.object,
}

export default LayoutWithTheme
