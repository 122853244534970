import React, { Component } from 'react'
import {
    CircularProgress,
    Typography,
    Divider,
    Button,
    Grid,
    Box,
    List,
    ListItem,
    Link,
} from '@mui/material'
import {
    Create,
    useCreate,
    useRedirect,
    useNotify,
    useDataProvider,
} from 'react-admin'
import { connect, useSelector } from 'react-redux'
import ImageSlider from '../../components/ImageSlider'
import { cleanUserName } from '../../components/componentHelpers'
import { get, includes, isEmpty } from 'lodash-es'
import { useLocation } from 'react-router'
import { HorizontalCardMedia } from '@thefront/pandipackV2'
import { useNavigate } from 'react-router-dom'
import { useThemeConfig } from '../../themeConfig'
import { ContentBlock } from '../../components/common'
import {
    BackButton,
    ConnectAppButton,
    InstancesButton,
    useHandleSave,
    saveTenant,
} from './IntegrationShow'

const basename = window.location.pathname.split('/')[1]

const hasLinks = (dev, doc, support) => {
    return dev || doc || support
}

const hasContent = (descriptions, images) => {
    return descriptions || images
}

const mapDescriptions = (descriptions) => {
    return descriptions && descriptions.length
        ? descriptions.map((obj, index) => (
              <span key={index}>
                  <Typography
                      variant="subtitle1"
                      sx={{
                          margin: '20px 0',
                          fontSize: '16px',
                          fontWeight: 'bold',
                      }}
                  >
                      {obj.Subtitle}
                  </Typography>
                  <Typography variant="body1" margin="8px 0">
                      <ContentBlock record={obj} source="Description" />
                  </Typography>
              </span>
          ))
        : null
}

const getIntegrationMedia = (integrationMedia) => {
    return integrationMedia && integrationMedia.length
        ? integrationMedia.map((obj) => obj.src)
        : null
}

const ExternalConnectButton = (props) => {
    const { integration, isExternalGreishInstalled } = props
    const theme = useThemeConfig()
    const marketplaceSettings = get(theme, 'configs.marketplaceSettings', {})

    return (
        <Button
            color="primary"
            variant="contained"
            target={get(
                integration,
                'marketplaceSettings.linkTarget',
                '_blank'
            )}
            href={get(integration, 'marketplaceSettings.externalURL')}
            rel="noopener noreferrer"
            children={
                isExternalGreishInstalled
                    ? marketplaceSettings.installedExternalIntegrationButton
                    : marketplaceSettings.externalIntegrationButton
            }
            sx={{
                margin: '24px 0',
                minWidth: '200px',
            }}
        />
    )
}

const SaveTenantButton = ({
    integration,
    integrationRelease,
    integrationReleaseChannel,
    hasReleases,
    isExternalGreishInstalled,
    integrationId,
    user,
}) => {
    const handleSave = useHandleSave(integration, integrationId, user)

    return integration.type !== 'External' ? (
        <ConnectAppButton
            handleSave={handleSave}
            integrationRelease={integrationRelease}
            integrationReleaseChannel={integrationReleaseChannel}
            hasReleases={hasReleases}
        />
    ) : (
        <ExternalConnectButton
            isExternalGreishInstalled={isExternalGreishInstalled}
            integration={integration}
        />
    )
}

const LinkText = (props) => {
    const theme = useThemeConfig()
    const { text, defaultText } = props
    return theme.configs.marketplaceSettings[text] ?? defaultText
}

const FullWidth = () => {
    const theme = useThemeConfig()
    return theme?.configs?.marketplaceSettings?.fullWidthMarketplace
}

export class IntegrationShow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            integration: {},
            integrationRelease: undefined,
            integrationReleaseChannel: undefined,
            installedTenants: 0,
            loading: true,
        }
    }

    componentDidMount() {
        const dataProvider = this.props.dataProvider
        Promise.all([
            dataProvider.getOne('integrations', {
                id: this.props.integrationId,
            }),
            dataProvider.getList('integrationreleases', {
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'created_date', order: 'DESC' },
                filter: {
                    integration_id: this.props.integrationId,
                },
            }),
            dataProvider.getList('tenants', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'created_date', order: 'DESC' },
                filter: {
                    connected_users__in: JSON.stringify([
                        cleanUserName(this.props.user.userName),
                        cleanUserName(this.props.user.aid),
                    ]),
                    integration_id: this.props.integrationId,
                },
            }),
        ])
            .then((res) => {
                const integration = res[0].data

                // get list of connector names to filter GET /connectors call
                const connectorList = get(integration, 'connectors')
                const connectorIds = connectorList?.map((connector) => {
                    return connector.name
                })

                return connectorIds?.length
                    ? Promise.all([
                          ...res,
                          dataProvider.getList('connectors', {
                              pagination: { page: 1, perPage: 100 }, // pagination and sorting are required for the getList call, but aren't actually being used
                              sort: { field: 'created_date', order: 'DESC' },
                              filter: {
                                  id: connectorIds,
                              },
                          }),
                      ])
                    : res
            })
            .then((res) => {
                const integration = res[0].data
                const integrationReleases = res[1].data
                const tenants = res[2].data
                const installedTenants = tenants.length
                const connectors = res[3]?.data ?? []

                this.setState({
                    integration,
                    connectors,
                    installedTenants,
                    hasReleases: !isEmpty(integrationReleases),
                    integrationRelease: integration.defaultReleaseId,
                    integrationReleaseChannel:
                        integration.defaultReleaseChannel,
                    loading: false,
                })
            })
            .catch((err) => {
                console.error(err)
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        const {
            integrationId,
            user,
            externalIntegrations,
            connectBackground,
            disconnectRequest,
            dataProvider,
            redirect,
            navigate,
            singleIntegrationView,
            ...props
        } = this.props

        const {
            integration,
            integrationRelease,
            integrationReleaseChannel,
            installedTenants,
            hasReleases,
            loading,
        } = this.state

        const breakpointStyle = (theme) => ({
            width: '776px',
            [theme.breakpoints.up('sm')]: {
                width: '896px',
            },
            [theme.breakpoints.up('md')]: {
                width: '960px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '1120px',
            },
            [theme.breakpoints.up('xl')]: {
                width: '1280px',
            },
        })

        const logo = get(integration, 'marketplaceSettings.primaryLogo')

        // The user has not installed an tenants and is using the connect anywhere feature (direct jwt access)
        const isSingleIntegrationViewInitVisit =
            installedTenants === 0 && singleIntegrationView

        // If there are no tenants installed and directly acceced with a jwt, create a new tenant and navigate to the edit tenant page
        if (isSingleIntegrationViewInitVisit && !loading) {
            const newTenantValues = {
                name: cleanUserName(user.userName),
                integration: integrationId,
                paused: get(integration, 'marketplaceSettings.paused', true),
                userSchedule:
                    integration.marketplaceSettings.schedule || '0 1 * * *',
                source: `imp-${basename}`,
                connected_users: {
                    [basename]: {
                        username: cleanUserName(user.userName),
                    },
                    pandium: { username: cleanUserName(user.userName) },
                },
            }
            saveTenant(
                newTenantValues,
                this.props.create,
                user,
                integration,
                this.props.notify,
                redirect,
                dataProvider
            )
        }

        return !isSingleIntegrationViewInitVisit && !loading ? (
            <Grid
                className={'pandium-integration-detail'}
                container
                sx={
                    FullWidth
                        ? { width: '100%' }
                        : (theme) => breakpointStyle(theme)
                }
            >
                <Grid
                    item
                    xs={12}
                    sx={{
                        marginBottom: '24px',
                    }}
                >
                    {!singleIntegrationView && <BackButton />}
                </Grid>
                <Grid item xs={3} paddingRight="32px">
                    <HorizontalCardMedia src={logo?.src ? logo.src : logo} />
                </Grid>
                <Grid item xs={6}>
                    <Box maxWidth="416px" paddingRight="32px">
                        <Typography variant="h4" margin="0 0 6px 0">
                            {integration.longName}
                        </Typography>
                        <Typography variant="body1">
                            <ContentBlock
                                record={integration}
                                source="marketplaceSettings.detailShortDescription"
                            />
                        </Typography>
                        <Create {...props} actions={false} component="div">
                            <SaveTenantButton
                                integrationId={integrationId}
                                integration={integration}
                                integrationRelease={integrationRelease}
                                integrationReleaseChannel={
                                    integrationReleaseChannel
                                }
                                hasReleases={hasReleases}
                                user={user}
                                isExternalGreishInstalled={includes(
                                    externalIntegrations,
                                    get(
                                        integration,
                                        'marketplaceSettings.externalId'
                                    )
                                )}
                            />
                        </Create>
                        {!!installedTenants && (
                            <Box
                                sx={{
                                    padding: '6px 20px',
                                    marginTop: '8px',
                                    backgroundColor: '#F5F5F5',
                                    border: '1px solid #DDDDDD',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    borderRadius: '4px',
                                    width: '406px',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <Typography variant="subtitle2">
                                    You have {installedTenants}{' '}
                                    {installedTenants === 1
                                        ? 'instance'
                                        : 'instances'}{' '}
                                    of {integration.longName} installed
                                </Typography>
                                <InstancesButton
                                    integrationId={integrationId}
                                    navigate={navigate}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>
                {hasLinks(
                    get(integration, 'marketplaceSettings.devURL'),
                    get(integration, 'marketplaceSettings.docURL'),
                    get(integration, 'marketplaceSettings.supportURL')
                ) && (
                    <Grid item xs={3}>
                        <List>
                            {[
                                {
                                    urlPath: 'devURL',
                                    textPath: 'devUrlText',
                                    defaultText: 'DEVELOPER WEBSITE',
                                },
                                {
                                    urlPath: 'docURL',
                                    textPath: 'docUrlText',
                                    defaultText: 'DOCUMENTATION',
                                },
                                {
                                    urlPath: 'supportURL',
                                    textPath: 'supportUrlText',
                                    defaultText: 'GET SUPPORT',
                                },
                            ].map(
                                (linkData) =>
                                    integration.marketplaceSettings?.[
                                        linkData.urlPath
                                    ] && (
                                        <ListItem
                                            disablePadding
                                            key={linkData.urlPath}
                                        >
                                            <Link
                                                target="_blank"
                                                href={
                                                    integration
                                                        .marketplaceSettings[
                                                        linkData.urlPath
                                                    ]
                                                }
                                                rel="noopener noreferrer"
                                                sx={(theme) => ({
                                                    fontSize: '14px',
                                                    margin: '5px 0',
                                                    display: 'inline-block',
                                                    color:
                                                        theme.palette.secondary
                                                            .main,
                                                    textDecoration: 'none',
                                                    fontWeight: 600,
                                                    letterSpacing: '1.25px',
                                                    lineHeight: '16px',
                                                })}
                                            >
                                                <LinkText
                                                    text={linkData.textPath}
                                                    defaultText={
                                                        linkData.defaultText
                                                    }
                                                />
                                            </Link>
                                        </ListItem>
                                    )
                            )}
                        </List>
                    </Grid>
                )}

                <Divider
                    sx={{ margin: '48px 0', height: '2px', width: '100%' }}
                />
                {hasContent(
                    get(integration, 'marketplaceSettings.longDescriptions'),
                    get(integration, 'marketplaceSettings.media')
                ) && (
                    <Box width="760px">
                        {get(
                            integration,
                            'marketplaceSettings.longDescriptions'
                        ).length !== 0 && (
                            <Typography variant="h5">
                                About {integration.longName}
                            </Typography>
                        )}
                        {mapDescriptions(
                            get(
                                integration,
                                'marketplaceSettings.longDescriptions'
                            )
                        )}
                        {get(integration, 'marketplaceSettings.media', [])
                            .length !== 0 && (
                            <ImageSlider
                                images={getIntegrationMedia(
                                    get(
                                        integration,
                                        'marketplaceSettings.media'
                                    )
                                )}
                            />
                        )}
                    </Box>
                )}
            </Grid>
        ) : (
            <CircularProgress />
        )
    }
}

function getIntegrationIDFromPath(pathname) {
    const splitPath = pathname.split('/')
    return splitPath[splitPath.length - 1]
}

const mapStateToProps = (state, props) => {
    return {
        user: state.user,
        externalIntegrations: state.user.externalIntegrations,
    }
}

function withDataProvider(ClassComponent) {
    return function WrappedComponent(props) {
        const dataProvider = useDataProvider()
        const location = useLocation()
        const integrationId = getIntegrationIDFromPath(location.pathname)
        const navigate = useNavigate()
        const redirect = useRedirect()
        const [create] = useCreate()
        const notify = useNotify()
        const singleIntegrationView = useSelector(
            (state) => state.user.singleIntegrationView
        )

        return (
            <ClassComponent
                dataProvider={dataProvider}
                redirect={redirect}
                integrationId={parseInt(integrationId)}
                navigate={navigate}
                create={create}
                notify={notify}
                singleIntegrationView={singleIntegrationView}
                {...props}
            />
        )
    }
}

export default withDataProvider(connect(mapStateToProps)(IntegrationShow))
