import React, { useState } from 'react'
import { Modal, Box, Fade, Backdrop } from '@mui/material'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

export default function ImageSlider({ images, makeGray = false }) {
    const [bigImage, setBigImage] = useState('')

    const isSingleImage = images.length === 1

    return (
        <>
            <Modal
                open={bigImage !== ''}
                onClose={() => setBigImage('')}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
            >
                <Fade in={bigImage !== ''}>
                    <Box
                        component="img"
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            maxWidth: '70%',
                            maxHeight: '70vh',
                            backgroundColor: '#f5f5f5',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                        }}
                        src={bigImage}
                        alt={bigImage}
                    />
                </Fade>
            </Modal>
            <Box
                className={'pandium-media-gallery'}
                sx={{
                    height: '480px',
                    width: '100%',
                    display: 'grid',
                    alignItems: 'center',
                }}
            >
                <Carousel
                    onClickItem={(idx) => setBigImage(images[idx])}
                    dynamicHeight={false}
                    showThumbs={!isSingleImage}
                    showStatus={!isSingleImage}
                    showIndicators={!isSingleImage}
                    renderThumbs={(children) =>
                        children.map(({ props }) => (
                            <Box
                                className={'pandium-thumbnail'}
                                sx={{
                                    ...props.style,
                                    minHeight: '80px',
                                }}
                            >
                                {props.children}
                            </Box>
                        ))
                    }
                >
                    {images.map((image, index) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'inherit',
                                height: '100%',
                            }}
                        >
                            <img
                                className={'pandium-main-image'}
                                src={image}
                                alt="carousel"
                                style={{
                                    maxHeight: isSingleImage
                                        ? '455px'
                                        : '360px',
                                    objectFit: 'contain',
                                    borderRadius: '16px',
                                    width: 'auto',
                                    height: 'auto',
                                    maxWidth: '100%',
                                    filter: makeGray && 'grayscale(1)',
                                }}
                            />
                        </div>
                    ))}
                </Carousel>
            </Box>
        </>
    )
}
