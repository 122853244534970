import React from 'react'
import { rankWith, uiTypeIs } from '@jsonforms/core'
import { MaterialLayoutRenderer } from '@jsonforms/material-renderers'
import { Typography } from '@mui/material'
import { Help } from '@mui/icons-material'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import RichTooltip from './RichTooltip'

export const sectionTester = rankWith(1000, uiTypeIs('Section'))

const sectionStyles = {
    toolTipsIcon: (theme) => ({
        color: theme.toolTips?.iconColor,
        height: '20px',
        width: '20px',
        alignItems: 'center',
        alignSelf: 'center',
        margin: '0 0 0 5px',
    }),
    titleAndTooltip: {
        display: 'inline-flex',
    },
    inputFields: {
        margin: '10px 0 0 12px',
    },
    subtitle: {
        color: 'grey',
        marginBottom: '17px',
    },
}

const SectionRenderer = ({ uischema, schema, path, visible, renderers }) => {
    const layoutProps = {
        elements: uischema.elements,
        schema: schema,
        path: path,
        direction: 'column',
        visible: visible,
        uischema: uischema,
        renderers: renderers,
    }
    return (
        <div>
            <Typography variant="h6" sx={sectionStyles.titleAndTooltip}>
                {uischema.label}
                {uischema.hintText && (
                    <RichTooltip title={uischema.hintText}>
                        <Help sx={sectionStyles.toolTipsIcon} />
                    </RichTooltip>
                )}
            </Typography>
            {uischema.subtitle && (
                <Typography variant="body2" sx={sectionStyles.subtitle}>
                    {uischema.subtitle}
                </Typography>
            )}
            <div style={sectionStyles.inputFields}>
                <MaterialLayoutRenderer {...layoutProps} />
            </div>
        </div>
    )
}

export default withJsonFormsLayoutProps(SectionRenderer)
