import React from 'react'
import { sanitize, addHook } from 'dompurify'
import { Tooltip } from '@mui/material'

export default ({ title, children }) => {
    addHook('afterSanitizeAttributes', (node) => {
        // set all links to target=_blank
        if (node.tagName === 'A') {
            node.setAttribute('target', '_blank')
            node.setAttribute('rel', 'noopener')
        }
    })

    return (
        <Tooltip
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        fontSize: '14px',
                        padding: '8px',
                    },
                },
            }}
            placement="right"
            title={
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitize(title),
                    }}
                />
            }
        >
            {children}
        </Tooltip>
    )
}
