import React, { useState, useEffect, useMemo } from 'react'
import {
    HorizontalCardMedia,
    convertNestedObjectToCamel,
} from '@thefront/pandipackV2'
import { Edit, useDataProvider, Form } from 'react-admin'
import { connect } from 'react-redux'
import {
    Box,
    CircularProgress,
    Grid,
    Paper,
    Tab,
    Tabs,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { filterUiSchema } from '../../components/componentHelpers'
import { RefreshConfigButton, FooterAction } from './TenantEditComponents'
import { get, isEmpty, cloneDeep } from 'lodash-es'
import ConnectionSettingsPage from './ConnectionSettingsPage'
import { useThemeConfig } from '../../themeConfig'
import SchedulePage, { ScheduleTab } from './SchedulePage'
import { ConnectorForm, ConfigsForm } from './ConnectionSettingsPage'
import { useLocation, useParams } from 'react-router-dom'

export const EditPageHeader = ({ integration }) => {
    return (
        <Grid
            container
            alignItems="center"
            spacing={3}
            sx={{
                marginBottom: '48px',
            }}
            className={'pandium-header'}
        >
            <Grid item xs={3}>
                <HorizontalCardMedia
                    src={integration?.marketplace_settings.primaryLogo}
                />
            </Grid>
            <Grid item xs={6}>
                <Typography variant="h4" className={'pandium-integration-name'}>
                    {integration?.long_name}
                </Typography>
            </Grid>
        </Grid>
    )
}

export const ConfigureTab = (props) => {
    const {
        tenant,
        jsonFormValues,
        setJsonFormValues,
        setIsJsonFormValid,
        prevTabValue,
        hasDynamicConfigs,
    } = props
    // *** state to support dynamic configs refresh ***
    const [previousSyncTime, setPreviousSyncTime] = useState(
        tenant.status.lastRun?.completionTime
    )
    // if the user is working through ftux and just came from the connect tab,
    // we know an init sync is running and we need to wait for it to finish
    const [syncing, setSyncing] = useState(prevTabValue === 0)
    // clicking the refresh configs button can also kick off an init sync
    const onRefreshClick = () => {
        setPreviousSyncTime(tenant.status.lastRun?.completionTime)
        setSyncing(true)
    }
    // if the last run completion time has changed, the sync is over
    if (syncing && previousSyncTime !== tenant.status.lastRun?.completionTime) {
        setSyncing(false)
    }

    return (
        <>
            {hasDynamicConfigs && (
                <RefreshConfigButton
                    connected={true}
                    jsonFormValues={jsonFormValues}
                    tenant={tenant}
                    disabled={syncing}
                    onClick={onRefreshClick}
                />
            )}
            <ConfigsForm
                syncing={hasDynamicConfigs && syncing}
                tenant={tenant}
                jsonFormValues={jsonFormValues}
                setJsonFormValues={setJsonFormValues}
                setIsJsonFormValid={setIsJsonFormValid}
                readonly={false}
            />
        </>
    )
}

const useTenantEditStyles = makeStyles((theme) => ({
    container: {
        width: '840px',
        margin: '24px',
        [theme.breakpoints.up('sm')]: {
            width: '896px',
        },
    },
    paperTab: {
        width: '100%',
        boxShadow: 'none',
    },
    tabs: {
        boxShadow: '0px 1px 0px 0px rgb(221, 221, 221)',
        marginBottom: '32px',
    },
    tab: {
        fontSize: '14px',
    },
    tabDisabled: {
        color: theme.palette.primary.main + ' !important',
    },
    edit: {
        '& div': {
            boxShadow: 'none',
        },
    },
}))

export const TenantEdit = ({ user }) => {
    const params = useParams()

    const location = useLocation()
    const page = location.pathname.split('/')[
        location.pathname.split('/').length - 1
    ]

    const [tenant, setTenant] = useState(undefined)

    const dataProvider = useDataProvider()
    const tenantCallback = (event) => {
        setTenant(event.payload)
    }
    // we are using this syntax instead of the useSubscribe
    // hook because something in the dependency array in that
    // component was changing every poll
    useEffect(() => {
        dataProvider.subscribe(`tenants/${params.id}`, tenantCallback)
        return () =>
            dataProvider.unsubscribe(`tenants/${params.id}`, tenantCallback)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const integrationRelease = cloneDeep(
        convertNestedObjectToCamel(tenant?.integrationRelease)
    )

    const classes = useTenantEditStyles()
    const [tabValue, setTabValue] = useState(0)
    const [prevTabValue, setPrevTablValue] = useState()
    const [jsonFormValues, setJsonFormValues] = useState(
        get(tenant, 'configs', {})
    )
    const [isJsonFormValid, setIsJsonFormValid] = useState(false)
    const hasDynamicConfigs = !isEmpty(
        get(integrationRelease, 'configSchema.schema.definitions')
    )

    const theme = useThemeConfig()
    const hasConfigs = !isEmpty(integrationRelease?.config_schema)

    const connected = useMemo(() => tenant?.status.auth.connected, [
        tenant?.status.auth,
    ])

    // Form is valid if no schema, let them pass
    useEffect(() => {
        if (!hasConfigs) {
            setIsJsonFormValid(true)
        }
    }, [hasConfigs])
    const handleTabChange = (event, tabValue, prevTabValue) => {
        setTabValue(tabValue)
        setPrevTablValue(prevTabValue)
    }

    const uischema = filterUiSchema(
        get(integrationRelease, 'configSchema.uischema')
    )
    const hasUserConfigs = !isEmpty(get(uischema, 'elements')) //for a case where all configs are admin only. there may be dynamic configs which are admin only

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const defaultValues = useMemo(() => tenant, [tenant?.name])

    return tenant ? (
        page === 'connection-settings' ? (
            <ConnectionSettingsPage
                {...params}
                tenant={tenant}
                integrationRelease={integrationRelease}
                hasDynamicConfigs={hasDynamicConfigs}
                theme={theme}
            />
        ) : page === 'schedule' ? (
            // Standalone schedule page
            <SchedulePage {...params} tenant={tenant} />
        ) : (
            <Box
                className={'pandium-ftux'}
                sx={(theme) => ({
                    width: '840px',
                    margin: '24px',
                    [theme.breakpoints.up('sm')]: {
                        width: '896px',
                    },
                })}
            >
                <EditPageHeader integration={tenant.integration} />
                <Grid>
                    <Paper className={classes.paperTab}>
                        <Tabs
                            indicatorColor="primary"
                            textColor="primary"
                            className={classes.tabs}
                            value={tabValue}
                            onChange={handleTabChange}
                        >
                            {['1. Connect', '2. Configure', '3. Schedule'].map(
                                (label, index) => (
                                    <Tab
                                        className={classes.tab}
                                        classes={{
                                            disabled: classes.tabDisabled,
                                        }}
                                        disabled
                                        label={label}
                                        key={index}
                                    />
                                )
                            )}
                        </Tabs>
                        <Edit
                            className={classes.edit}
                            actions={null}
                            {...params}
                            undoable={false}
                            mutationMode="pessimistic"
                        >
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault()
                                }}
                                defaultValues={defaultValues}
                            >
                                <>
                                    {tabValue === 0 && (
                                        <ConnectorForm tenant={tenant} />
                                    )}
                                    {tabValue === 1 && (
                                        <ConfigureTab
                                            tenant={tenant}
                                            prevTabValue={prevTabValue}
                                            hasDynamicConfigs={
                                                hasDynamicConfigs
                                            }
                                            jsonFormValues={jsonFormValues}
                                            setJsonFormValues={
                                                setJsonFormValues
                                            }
                                            setIsJsonFormValid={
                                                setIsJsonFormValid
                                            }
                                        />
                                    )}
                                    {tabValue === 2 && (
                                        <ScheduleTab
                                            tenant={tenant}
                                            theme={theme}
                                        />
                                    )}
                                    <FooterAction
                                        tabValue={tabValue}
                                        handleTabChange={handleTabChange}
                                        jsonFormValues={jsonFormValues}
                                        tenant={tenant}
                                        connected={connected}
                                        isJsonFormValid={isJsonFormValid}
                                        hasDynamicConfigs={hasDynamicConfigs}
                                        hasUserConfigs={hasUserConfigs}
                                        user={user}
                                    />
                                </>
                            </Form>
                        </Edit>
                    </Paper>
                </Grid>
            </Box>
        )
    ) : (
        <CircularProgress />
    )
}

const mapStateToProps = (state, props) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(TenantEdit)
