import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Typography } from '@mui/material'

const useSubtitleStyles = makeStyles({
    subtitle: {
        color: 'grey',
        marginBottom: '17px',
    },
})

const Subtitle = ({ uischema }) => {
    const classes = useSubtitleStyles()
    return (
        <Typography variant={'body2'} className={classes.subtitle}>
            {' '}
            {uischema.text}{' '}
        </Typography>
    )
}
export default Subtitle
