import { Typography, Button, Box } from '@mui/material'

export const IntegrationHeroBanner = ({ marketplaceSettings }) => {
    const {
        gradient,
        backgroundColor,
        gradientSecondaryColor,
        gradientAngle,
        height,
        backgroundImage,
        textColor,
        secondaryTextColor,
        heroImage,
        secondaryButton,
        primaryButton,
        heroBannerTitleText,
        heroBannerSubText,
        heroBannerPrimaryBtnText,
        heroBannerSecondaryBtnText,
    } = marketplaceSettings?.heroBanner?.integrationList

    return (
        <Box
            className={'pandium-hero-banner'}
            sx={{
                width: '100%',
                height: height ? `${height}px` : '400px',
                background: gradient
                    ? `linear-gradient(${gradientAngle}deg, ${backgroundColor} 0%, ${gradientSecondaryColor} 100%)`
                    : backgroundColor,
                display: 'flex',
                justifyContent: 'center',
                ...(backgroundImage && {
                    backgroundImage: `url('${backgroundImage}')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }),
                alignItems: 'center',
            }}
        >
            <Box
                sx={(theme) => ({
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '5%',
                    gap: '25px',
                    maxHeight: '100%',
                    [theme.breakpoints.down('sm')]: {
                        width: '75%',
                        paddingTop: '25px',
                    },
                })}
            >
                <Typography
                    className={'pandium-main-text'}
                    variant="h3"
                    color={textColor}
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            textAlign: 'center',
                        },
                    })}
                >
                    {heroBannerTitleText}
                </Typography>
                <Typography
                    className={'pandium-sub-text'}
                    variant="h6"
                    color={secondaryTextColor}
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            textAlign: 'center',
                        },
                    })}
                >
                    {heroBannerSubText}
                </Typography>
                <Box
                    component="img"
                    src={heroImage}
                    sx={(theme) => ({
                        display: 'none',
                        overflow: 'hidden',
                        height: 'fit-content',
                        width: 'fit-content',
                        alignSelf: 'center',
                        [theme.breakpoints.down('sm')]: {
                            display: 'flex',
                        },
                    })}
                />
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        marginTop: '25px',
                        gap: '20px',
                        marginBottom: '25px',
                        [theme.breakpoints.down('sm')]: {
                            justifyContent: 'center',
                        },
                    })}
                >
                    {secondaryButton?.enabled && (
                        <Button
                            className={'pandium-primary-button'}
                            variant="contained"
                            color="secondary"
                            href={secondaryButton?.url}
                            target="_blank"
                        >
                            {heroBannerSecondaryBtnText}
                        </Button>
                    )}
                    {primaryButton?.enabled && (
                        <Button
                            className={'pandium-secondary-button'}
                            variant="contained"
                            color="primary"
                            href={primaryButton?.url}
                            target="_blank"
                        >
                            {heroBannerPrimaryBtnText}
                        </Button>
                    )}
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    width: '50%',
                    flexGrow: '1',
                    marginRight: '5%',
                    display: 'flex',
                    justifyContent: 'center',
                    [theme.breakpoints.down('sm')]: {
                        display: 'none',
                    },
                })}
            >
                <Box
                    component="img"
                    src={heroImage}
                    sx={{
                        maxHeight: height ? `${height}px` : '400px',
                        padding: '5% 0',
                    }}
                />
            </Box>
        </Box>
    )
}
