import React, { useState } from 'react'
import {
    Typography,
    Divider,
    Box,
    List,
    ListItemButton,
    ListItemText,
} from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const CategoryList = ({
    title,
    selected,
    integrationLabels,
    setSelectedCategory,
}) => {
    const [toggledParent, setToggledParent] = useState('')
    return (
        <Box
            className={'pandium-category-list'}
            sx={(theme) => ({
                flexGrow: 1,
                width: '166px',
                minWidth: '166px',
                marginRight: '32px',
                [theme.breakpoints.up('sm')]: {
                    width: '196px',
                    minWidth: '196px',
                },
                [theme.breakpoints.up('md')]: {
                    width: '214px',
                    minWidth: '214px',
                },
                [theme.breakpoints.up('lg')]: {
                    width: '256px',
                    minWidth: '256px',
                },
                [theme.breakpoints.up('xl')]: {
                    width: '296px',
                    minWidth: '296px',
                },
            })}
        >
            <Typography
                sx={(theme) => ({
                    letterSpacing: '1px',
                    fontSize: '16px',
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    color: theme.palette.primary.main,
                })}
                component="p"
                className={'pandium-category-title'}
            >
                {title}
            </Typography>
            <List>
                <ListItemButton
                    sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        },
                    }}
                    selected={selected === ''}
                    onClick={() => {
                        setSelectedCategory('')
                    }}
                >
                    <ListItemText
                        primary={
                            <Typography
                                className={`pandium-category-name ${
                                    selected === '' ? 'pandium-active' : ''
                                }`}
                                sx={{
                                    fontWeight: selected === '' ? '600' : '400',
                                    fontSize: '0.9rem',
                                    textTransform: 'capitalize',
                                }}
                            >
                                View all
                            </Typography>
                        }
                        disableTypography
                    ></ListItemText>
                </ListItemButton>
                {integrationLabels.map((label, idx) => {
                    if (label.labelType.toLowerCase() === 'section break') {
                        return <Divider key={label.id} />
                    }

                    const [parentName, currentCatName] = label.name.includes(
                        '--'
                    )
                        ? label.name.split('--')
                        : [null, label.name]

                    const isParent =
                        !parentName &&
                        integrationLabels[idx + 1]?.name.includes(
                            currentCatName
                        )

                    // if the current label has a parent, only show if its
                    // parent is toggled
                    if (parentName && parentName !== toggledParent) {
                        return null
                    }

                    return (
                        <ListItemButton
                            sx={() => ({
                                '&.Mui-selected, &.Mui-selected:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                                ...(parentName
                                    ? {
                                          marginLeft: '20px',
                                      }
                                    : {
                                          marginBottom: '8px',
                                          marginTop: '8px',
                                      }),
                            })}
                            key={label.id}
                            selected={selected === currentCatName}
                            onClick={() => {
                                isParent &&
                                    setToggledParent(
                                        toggledParent === currentCatName
                                            ? ''
                                            : currentCatName
                                    )
                                setSelectedCategory(currentCatName)
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        sx={{
                                            fontWeight:
                                                selected === currentCatName
                                                    ? '600'
                                                    : '400',
                                            fontSize: '0.9rem',
                                        }}
                                        className={`pandium-category-name ${
                                            selected === currentCatName
                                                ? 'pandium-active'
                                                : ''
                                        }`}
                                    >
                                        {currentCatName}
                                    </Typography>
                                }
                                disableTypography
                            />
                            {isParent &&
                                (toggledParent === currentCatName ? (
                                    <KeyboardArrowDownIcon />
                                ) : (
                                    <KeyboardArrowRightIcon />
                                ))}
                        </ListItemButton>
                    )
                })}
            </List>
        </Box>
    )
}
