//https://marmelab.com/react-admin/Actions.html

import React from 'react'
import { connect } from 'react-redux'
import { Button, IconButton } from '@mui/material'
import { defer } from 'lodash-es'
import { trackUmamiEvent } from '@thefront/pandipackV2'
import { useNotify, useDataProvider } from 'react-admin'

const SyncButton = ({
    iconButton,
    disabled,
    sx,
    color,
    children,
    variant,
    record,
    user,
    className,
    // parent can optionally pass in an extra callback to update state
    onClick = null,
    ...props
}) => {
    const dataProvider = useDataProvider()

    const notify = useNotify()

    const handleClick = () => {
        const { shouldNavigate, navigate, mode } = props
        const success_notification = mode === 'init' ? 'Updating...' : 'Syncing'

        dataProvider
            .SYNC_INITIATED('runs', {
                data: {
                    tenantId: record.id,
                    mode: mode,
                },
            })
            .then((res) => {
                if (res.data) notify(success_notification)
            })

        onClick && onClick()
        trackUmamiEvent(
            `user: ${user.userName}, ${record.name}`,
            'Sync',
            record.id,
            'tenants',
            user.userID
        )

        defer(() => {
            if (shouldNavigate) {
                navigate('/tenants/' + record.name + '/show')
            }
        })
    }

    return iconButton ? (
        <IconButton
            className={className}
            aria-label={'sync-button'}
            disabled={disabled}
            sx={sx}
            color={color}
            onClick={handleClick}
            size="large"
        >
            {children}
        </IconButton>
    ) : (
        <Button
            aria-label={'sync-button'}
            className={className}
            disabled={disabled}
            sx={sx}
            variant={variant}
            color={color}
            onClick={handleClick}
        >
            {children}
        </Button>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(SyncButton)
