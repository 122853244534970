import React from 'react'
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    Typography,
} from '@mui/material'
import SyncButton from '../../components/SyncButton'
import { isEmpty } from 'lodash-es'
import { alpha } from '@mui/material/styles'
import RefreshIcon from '@mui/icons-material/Refresh'
import { formatDate, trackUmamiEvent } from '@thefront/pandipackV2'
import { useThemeConfig } from '../../themeConfig'
import { useDataProvider, useSaveContext, useNotify } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { useFormState } from 'react-hook-form'
import { useSelector } from 'react-redux'

export function SaveTenantConfigsButton({
    configs,
    tenant,
    user,
    syncing,
    disabled,
}) {
    const { save } = useSaveContext()
    const { isSubmitting } = useFormState()
    const navigate = useNavigate()
    const notify = useNotify()
    const singleIntegrationView = useSelector(
        (state) => state.user.singleIntegrationView
    )

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={disabled || isSubmitting || syncing}
            onClick={() => {
                save(
                    { configs: configs },
                    {
                        onSuccess: () => {
                            notify('Save success!')
                            navigate('/tenants', {
                                state: singleIntegrationView
                                    ? {
                                          integration: `${tenant.integration.id}`,
                                      }
                                    : {},
                            })
                        },
                        onFailure: (error) => {
                            notify('Failed to save.', 'warning')
                            console.error(error)
                        },
                    }
                )

                trackUmamiEvent(
                    `user: ${user.userName}, ${tenant.name}`,
                    'Configure',
                    tenant.id,
                    'tenants',
                    user.userID
                )
            }}
        >
            Save
        </Button>
    )
}

const footerActionStyles = {
    divider: {
        margin: '50px 0',
        height: '1px',
    },
    cancelButton: (theme) => ({
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.12),
        },
    }),
    actionsGrid: (props) => ({
        height: '69px',
        alignContent: 'flex-end',
        marginBottom: '10px',
        '& button': {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '1.25px',
            height: '36px',
            marginRight: props.tabValue === 2 ? '36px' : '40px',
        },
        '& button:nth-child(1)': {
            width: '80px',
        },
        '& button:nth-child(2)': {
            width: '96px',
        },
        '& button:nth-child(3)': {
            width: '180px',
        },
    }),
}

export const FooterAction = (props) => {
    const {
        tabValue,
        handleTabChange,
        jsonFormValues,
        tenant,
        connected,
        hasDynamicConfigs,
        hasUserConfigs,
        isJsonFormValid,
        user,
    } = props
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const navigate = useNavigate()
    const { save } = useSaveContext()
    const singleIntegrationView = useSelector(
        (state) => state.user.singleIntegrationView
    )

    const sync = (id, mode) =>
        dataProvider.SYNC_INITIATED('runs', {
            data: {
                tenantId: id,
                mode: mode,
            },
        })

    const hasConfigs =
        hasUserConfigs && !isEmpty(tenant?.integrationRelease?.config_schema)
    return (
        <div role={'toolbar'}>
            <Divider sx={footerActionStyles.divider} />
            <Grid
                className={'pandium-buttons'}
                sx={() => footerActionStyles.actionsGrid({ tabValue })}
            >
                {tabValue === 0 && (
                    <>
                        <Button
                            className="pandium-cancel"
                            sx={footerActionStyles.cancelButton}
                            onClick={() =>
                                dataProvider
                                    .delete('tenants', { id: tenant.id })
                                    .then(() => {
                                        trackUmamiEvent(
                                            `user: ${user.userName}, ${tenant.name}`,
                                            'Delete',
                                            tenant.integration?.id,
                                            'integrations',
                                            user.userID
                                        )
                                        notify('Installation cancelled')
                                        navigate('/tenants', {
                                            state: singleIntegrationView
                                                ? {
                                                      integration: `${tenant.integration.id}`,
                                                  }
                                                : {},
                                        })
                                    })
                                    .catch(() => notify('something went wrong'))
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            aria-label={'Next'}
                            className="pandium-save"
                            sx={footerActionStyles.boldButton}
                            color="primary"
                            variant="contained"
                            disabled={!connected} //they MUSSST connect first
                            onClick={(e) => {
                                // Skip config tab if there are no configs
                                const newTabValue = !hasConfigs
                                    ? tabValue + 2
                                    : tabValue + 1
                                handleTabChange(e, newTabValue, tabValue)
                                // Only run init sync if tenant has dynamic configs
                                if (hasDynamicConfigs) {
                                    sync(tenant.id, 'init')
                                }
                            }}
                        >
                            Next
                        </Button>
                    </>
                )}
                {tabValue === 1 && (
                    <>
                        <Button
                            className="pandium-cancel"
                            color="primary"
                            onClick={(e) =>
                                handleTabChange(e, tabValue - 1, tabValue)
                            }
                        >
                            Back
                        </Button>
                        <Button
                            className="pandium-save"
                            sx={footerActionStyles.boldButton}
                            color="primary"
                            variant="contained"
                            disabled={!isJsonFormValid}
                            onClick={(e) =>
                                handleTabChange(e, tabValue + 1, tabValue)
                            }
                        >
                            Next
                        </Button>
                    </>
                )}
                {tabValue === 2 && (
                    <>
                        <Button
                            className="pandium-cancel"
                            color="primary"
                            onClick={(e) => {
                                const newTabValue = !hasConfigs
                                    ? tabValue - 2
                                    : tabValue - 1
                                handleTabChange(e, newTabValue, tabValue)
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className="pandium-save"
                            variant="contained"
                            color="primary"
                            disabled={!isJsonFormValid}
                            onClick={() => {
                                save(
                                    { configs: jsonFormValues },
                                    {
                                        onSuccess: () => {
                                            notify('Save success!')
                                            navigate('/tenants', {
                                                state: singleIntegrationView
                                                    ? {
                                                          integration: `${tenant.integration.id}`,
                                                      }
                                                    : {},
                                            })
                                        },
                                        onFailure: (error) => {
                                            notify('Failed to save.', 'warning')
                                            console.error(error)
                                        },
                                    }
                                )
                                trackUmamiEvent(
                                    `user: ${user.userName}, ${tenant.name}`,
                                    'Configure',
                                    tenant.id,
                                    'tenants',
                                    user.userID
                                )
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            className="pandium-save-and-sync"
                            variant="outlined"
                            color="primary"
                            disabled={!isJsonFormValid}
                            onClick={() => {
                                save(
                                    { configs: jsonFormValues },
                                    {
                                        onSuccess: () => {
                                            notify('Save success... Syncing!')
                                            sync(tenant.id, 'normal')
                                            navigate('/tenants', {
                                                state: singleIntegrationView
                                                    ? {
                                                          integration: `${tenant.integration.id}`,
                                                      }
                                                    : {},
                                            })
                                        },
                                        onFailure: (error) => {
                                            notify('Failed to save.', 'warning')
                                            console.error(error)
                                        },
                                    }
                                )

                                trackUmamiEvent(
                                    `user: ${user.userName}, ${tenant.name}`,
                                    'Configure',
                                    tenant.id,
                                    'tenants',
                                    user.userID
                                )
                            }}
                        >
                            Save + sync now
                        </Button>
                    </>
                )}
            </Grid>
        </div>
    )
}

const refactorTimeStamp = (time, dateFormat) => {
    // 2019-11-18T21:15:24Z to 11/18/2019, 4:15:24 PM
    const t = new Date(time)
    return formatDate(t.toLocaleString(), false, dateFormat)
}

export const RefreshConfigButton = (props) => {
    const {
        jsonFormValues,
        tenant,
        connected,
        onClick,
        disabled = false,
    } = props
    const hasStatus = tenant && tenant.status
    const hasRunTime = hasStatus && !isEmpty(tenant.status.lastSuccessfulRun)
    const { configs } = useThemeConfig()
    return (
        <span
            style={{
                display: 'flex',
                alignItems: 'flex-end',
                '& svg': {
                    marginBottom: '-11px',
                },
                justifyContent: 'flex-end',
            }}
        >
            <Typography>
                {' '}
                {'Options loaded '}
                {!hasStatus ? (
                    <CircularProgress color={'primary'} thickness={8} />
                ) : hasRunTime ? (
                    refactorTimeStamp(
                        tenant.status.lastSuccessfulRun.completionTime,
                        configs.dateFormat
                    )
                ) : (
                    'never'
                )}
            </Typography>
            <SyncButton
                disabled={!connected || disabled}
                color={'primary'}
                mode={'init'}
                shouldNavigate={false}
                iconButton={true}
                record={
                    tenant && {
                        ...tenant,
                        configs: jsonFormValues,
                    }
                }
                update={true}
                prevTenantData={tenant}
                onClick={onClick ?? null}
            >
                <RefreshIcon />
            </SyncButton>
        </span>
    )
}
