import { CircularProgress, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    text: {
        margin: '15px 0',
    },
})

/**
 * A loader that accepts text that is displayed beneath it
 * @param props
 * @returns {*}
 */
export default (props) => {
    const classes = useStyles()
    const { text } = props

    return (
        <div className={classes.root}>
            <CircularProgress />
            <Typography
                className={classes.text}
                variant="subtitle2"
                color={'textSecondary'}
            >
                {' '}
                {text}{' '}
            </Typography>
        </div>
    )
}
