import React, { useState, useEffect } from 'react'

// Have to do import from es5 for this to work when we build production build.
import { LazyLog } from 'react-lazylog'
import { Card, CardHeader } from '@mui/material'
import { API_URI, HEADERS } from '../themeConfig'

export const LogField = (props) => {
    let { record } = props
    const [headers, setHeaders] = useState('')

    useEffect(() => {
        const token = sessionStorage.getItem('token')
        setHeaders(HEADERS(token))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        //Add actions (reverse, follow and download)
        <Card>
            <CardHeader title={'Log'} />
            <div>
                {headers !== '' && (
                    <LazyLog
                        style={{ overflowX: 'auto', overflowY: 'auto' }}
                        containerStyle={{ overflow: 'initial' }}
                        extraLines={1}
                        height={500}
                        width="auto"
                        url={`${API_URI}/runs/${record.id}/log`}
                        fetchOptions={{ headers: headers }}
                        follow={true}
                        selectableLines={true}
                    />
                )}
            </div>
        </Card>
    )
}
