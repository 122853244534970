import React, { useState, useMemo, useEffect } from 'react'
import {
    Box,
    Grid,
    Typography,
    Divider,
    Button,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import { Form, Edit, useNotify, useDelete } from 'react-admin'
import { isEmpty, get } from 'lodash-es'
import { connect, useSelector } from 'react-redux'
import CustomCircularProgress from '../../components/CustomCircularProgress'
import { JsonFormsWrapper } from '../../components/jsonforms/JsonFormsWrapper'
import ConnectForm from '../../components/Auth/ConnectForm'
import {
    trackUmamiEvent,
    getDynamicConfigSchema,
    convertNestedObjectToCamel,
} from '@thefront/pandipackV2'
import {
    RefreshConfigButton,
    SaveTenantConfigsButton,
} from './TenantEditComponents'
import { Help } from '@mui/icons-material'
import { EditPageHeader } from './TenantEdit'
import { filterUiSchema } from '../../components/componentHelpers'
import { ContentBlock } from '../../components/common'
import { useThemeConfig } from '../../themeConfig'
import { useNavigate } from 'react-router-dom'

const connectorFormStyles = {
    toolTipsIcon: (theme) => ({
        color: theme.toolTips?.iconColor,
        height: '20px',
        width: '20px',
        alignItems: 'center',
        alignSelf: 'center',
        margin: '0 0 0 5px',
    }),
    tooltip: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        color: '#FFFFFF',
        textAlign: 'center',
        backgroundColor: 'black',
    },
    titleAndTooltip: {
        display: 'inline-flex',
    },
}

export const ConnectorForm = ({ tenant }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const integration = useMemo(() => tenant.integration, [tenant?.name])
    return (
        <Box sx={{ width: '714px' }} className={'pandium-connector-frame'}>
            <Typography
                variant="h6"
                sx={connectorFormStyles.titleAndTooltip}
                className="pandium-main-text"
            >
                Connect your account
                {integration?.marketplace_settings?.connectionTooltip && (
                    <Tooltip
                        title={
                            integration?.marketplace_settings?.connectionTooltip
                        }
                        placement="right-start"
                        PopperProps={{
                            sx: {
                                '& .MuiTooltip-tooltip':
                                    connectorFormStyles.tooltip,
                            },
                        }}
                    >
                        <Help sx={connectorFormStyles.toolTipsIcon} />
                    </Tooltip>
                )}
            </Typography>
            <ContentBlock
                record={integration}
                source="marketplace_settings.connectionSettingsContent"
            />
            <ConnectForm tenant={tenant} />
        </Box>
    )
}

// React.memo is like React.PureComponent
// https://reactjs.org/docs/react-api.html#reactmemo
export const ConfigsForm = React.memo(
    ({
        syncing,
        tenant,
        jsonFormValues,
        setJsonFormValues,
        setIsJsonFormValid,
        readonly,
    }) => {
        const integrationRelease = convertNestedObjectToCamel(
            tenant.integrationRelease
        )
        // Call back to get state from child
        const onJsonFormChange = (errors, values) => {
            // TODO causes infinite loop when setting errors to state
            setJsonFormValues(values)
            setIsJsonFormValid(isEmpty(errors))
        }
        let schema

        try {
            schema = getDynamicConfigSchema(
                get(integrationRelease, 'configSchema.schema'),
                tenant
            )
        } catch (err) {
            schema = err
        }

        const uischema = filterUiSchema(
            get(integrationRelease, 'configSchema.uischema')
        )

        return (
            <Box
                sx={{
                    marginBottom: '40px',
                    marginTop: '40px',
                }}
                className={'pandium-tenant-configs'}
                aria-label="configuration form"
            >
                {!syncing && schema ? (
                    schema instanceof Error ? (
                        <div>
                            Something went wrong with the app configuration,
                            please contact support.
                        </div>
                    ) : (
                        <JsonFormsWrapper
                            onJsonFormChange={onJsonFormChange}
                            schema={schema}
                            uischema={uischema}
                            data={
                                !isEmpty(jsonFormValues)
                                    ? jsonFormValues
                                    : tenant.configs
                            }
                            label="configs"
                            readonly={readonly}
                            dynamicConfigs={tenant?.status.dynamicConfigs}
                        />
                    )
                ) : (
                    <CustomCircularProgress text="Loading configurations. This may take a few minutes." />
                )}
            </Box>
        )
    },
    (prevProps, nextProps) =>
        prevProps.syncing === nextProps.syncing &&
        nextProps.readonly === prevProps.readonly
)

const DeleteWithConfirmButton = ({ tenant, user, singleIntegrationView }) => {
    const navigate = useNavigate()
    const notify = useNotify()
    const [deleteOne, { loading }] = useDelete(
        'tenants',
        { id: tenant.id },
        {
            onSuccess: () => {
                notify('App uninstalled')
                singleIntegrationView
                    ? navigate('/tenants', {
                          state: { integration: `${tenant.integration.id}` },
                      })
                    : navigate('/integrations')
                trackUmamiEvent(
                    `user: ${user.userName}, ${tenant.name}`,
                    'Delete',
                    tenant.integration?.id,
                    'integrations',
                    user.userID
                )
            },
        }
    )

    const [open, setOpen] = useState(false)

    return (
        <>
            <Button
                sx={{
                    color: '#B00020',
                    width: '128px',
                    textTransform: 'uppercase',
                }}
                variant="outlined"
                onClick={() => setOpen(true)}
            >
                Uninstall
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    Uninstall {tenant.integration.long_name}
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to uninstall this app?
                </DialogContent>
                <DialogActions>
                    {' '}
                    <Button disabled={loading} onClick={(e) => deleteOne()}>
                        Yes, uninstall
                    </Button>
                    <Button variant="contained" onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const connectionSettingsStyles = {
    container: (theme) => ({
        minWidth: '840px',
        marginBottom: '24px',
        justifyContent: theme.marketplaceSettings.justify || 'left',
        [theme.breakpoints.up('xs')]: {
            width: '776px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '794px',
        },
    }),
    bodyWidth: (theme) => ({
        [theme.breakpoints.up('xs')]: {
            width: '776px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '794px',
        },
    }),
    justifyBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerBar: {
        height: '45px',
        alignItems: 'flex-end',
    },
    buttonBar: {
        height: '69px',
        alignContent: 'flex-end',
        marginBottom: '10px',
        '& div': {
            display: 'flex',
            '& button': {
                fontWeight: 'bold',
                fontSize: '14px',
                lineHeight: '16px',
                letterSpacing: '1.25px',
                height: '36px',
            },
        },
    },
}

export const ConnectionSettingsPage = ({
    tenant,
    integrationRelease,
    hasDynamicConfigs,
    user,
}) => {
    const navigate = useNavigate()
    const singleIntegrationView = useSelector(
        (state) => state.user.singleIntegrationView
    )
    const theme = useThemeConfig()
    const hasConfigs = integrationRelease && integrationRelease.configSchema
    const [isJsonFormValid, setIsJsonFormValid] = useState(true)
    const [jsonFormValues, setJsonFormValues] = useState(
        get(tenant, 'configs', {})
    )

    const connected = useMemo(() => tenant?.status.auth.connected, [
        tenant?.status.auth,
    ])

    //prevent stale configs
    useEffect(() => setJsonFormValues(tenant.configs), [tenant.configs])

    //state to support dynamic configs refresh
    const [previousSyncTime, setPreviousSyncTime] = useState(null)
    const [syncing, setSyncing] = useState(false)
    const onRefreshClick = () => {
        setPreviousSyncTime(tenant.status.lastRun?.completionTime)
        setSyncing(true)
    }
    if (syncing && previousSyncTime !== tenant.status.lastRun?.completionTime) {
        setSyncing(false)
    }

    return (
        <Grid
            container
            sx={connectionSettingsStyles.container}
            className={'pandium-connection-settings-page'}
        >
            <EditPageHeader
                integration={tenant.integration}
                sx={connectionSettingsStyles.bodyWidth}
            />
            <Grid
                container
                sx={{
                    ...connectionSettingsStyles.headerBar,
                    ...connectionSettingsStyles.justifyBetween,
                    ...connectionSettingsStyles.bodyWidth,
                }}
            >
                <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
                    Connection settings
                </Typography>
                {hasDynamicConfigs && (
                    <RefreshConfigButton
                        connected={connected}
                        tenant={tenant}
                        onClick={onRefreshClick}
                        disabled={syncing}
                    />
                )}
                <Grid item xs={12} sx={connectionSettingsStyles.bodyWidth}>
                    <Divider />
                </Grid>
            </Grid>
            <Edit
                component="div"
                actions={null}
                id={tenant.id}
                resource="tenants"
                undoable={false}
                redirect={
                    theme.configs.marketplaceSettings.separateApps
                        ? '/tenants'
                        : '/'
                }
                mutationMode="pessimistic"
            >
                <Form
                    onSubmit={(e) => e.preventDefault()}
                    defaultValues={tenant}
                >
                    <ConnectorForm tenant={tenant} />
                    {hasConfigs && (
                        <>
                            <Divider sx={connectionSettingsStyles.bodyWidth} />
                            <ConfigsForm
                                syncing={syncing}
                                tenant={tenant}
                                jsonFormValues={jsonFormValues}
                                setJsonFormValues={setJsonFormValues}
                                setIsJsonFormValid={setIsJsonFormValid}
                                readonly={!connected}
                            />
                        </>
                    )}
                    <Divider sx={connectionSettingsStyles.bodyWidth} />
                    <Grid
                        container
                        sx={{
                            ...connectionSettingsStyles.justifyBetween,
                            ...connectionSettingsStyles.buttonBar,
                            ...connectionSettingsStyles.bodyWidth,
                        }}
                    >
                        <Grid
                            item
                            xs={3}
                            sx={connectionSettingsStyles.justifyBetween}
                        >
                            <Button
                                color="primary"
                                onClick={() => {
                                    theme.configs.marketplaceSettings
                                        .separateApps
                                        ? navigate('/tenants', {
                                              state: singleIntegrationView
                                                  ? {
                                                        integration: `${tenant.integration.id}`,
                                                    }
                                                  : {},
                                          })
                                        : navigate('/')
                                }}
                                sx={{
                                    textTransform: 'uppercase',
                                }}
                            >
                                Cancel
                            </Button>
                            <SaveTenantConfigsButton
                                navigate
                                configs={jsonFormValues}
                                tenant={tenant}
                                user={user}
                                syncing={syncing}
                                disabled={!connected || !isJsonFormValid}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DeleteWithConfirmButton
                                tenant={tenant}
                                user={user}
                                singleIntegrationView={singleIntegrationView}
                            />
                        </Grid>
                    </Grid>
                </Form>
            </Edit>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(mapStateToProps)(ConnectionSettingsPage)
