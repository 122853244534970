import React from 'react'
import Slider from 'react-slick'
import { Box, Typography } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IntegrationCard } from './integrationCardUtils'
import { connect } from 'react-redux'

const CustomArrow = (props) => {
    const { className, onClick, direction } = props
    return (
        <Box
            className={className}
            sx={{
                height: '380px !important',
                width: '30px !important',
                justifyContent: 'center',
                display: 'flex !important',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: 'rgb(244, 244, 244) !important',
            }}
            onClick={onClick}
        >
            {direction === 'prev' ? (
                <ArrowBackIosNewIcon sx={{ color: 'black' }} />
            ) : (
                <ArrowForwardIosIcon sx={{ color: 'black' }} />
            )}
        </Box>
    )
}

function IntegrationCarousel({ integrations, marketplaceSettings, user }) {
    const width = marketplaceSettings.carouselWidth ?? 1
    const sliderTitle = marketplaceSettings?.carouselTitle ?? 'Featured'
    const slides = integrations.length < 8 ? integrations.length : 8
    const settings = {
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: slides,
        // variableWidth resizes the containers around the cards to keep it responsive
        // We turn this off when there are less cards than the screen can hold to center them on the page
        variableWidth: integrations.length < 8,
        prevArrow: <CustomArrow direction={'prev'} />,
        nextArrow: <CustomArrow direction={'next'} />,
        responsive: [
            {
                breakpoint: marketplaceSettings?.fullWidthMarketplace
                    ? 3000
                    : -1,
                settings: {
                    slidesToShow:
                        integrations.length >= 7 ? 7 : integrations.length,
                    variableWidth: integrations.length <= 7,
                },
            },
            {
                breakpoint: marketplaceSettings?.fullWidthMarketplace
                    ? 2600
                    : -1,
                settings: {
                    slidesToShow:
                        integrations.length >= 6 ? 6 : integrations.length,
                    variableWidth: integrations.length <= 6,
                },
            },
            {
                breakpoint: marketplaceSettings?.fullWidthMarketplace
                    ? 2000
                    : -1,
                settings: {
                    slidesToShow:
                        integrations.length >= 5 ? 5 : integrations.length,
                    variableWidth: integrations.length <= 5,
                },
            },
            {
                breakpoint: marketplaceSettings?.fullWidthMarketplace
                    ? 1600
                    : 3000,
                settings: {
                    slidesToShow:
                        integrations.length >= 4 ? 4 : integrations.length,
                    variableWidth: integrations.length <= 4,
                },
            },
            {
                breakpoint: marketplaceSettings?.fullWidthMarketplace
                    ? 1350
                    : 1800,
                settings: {
                    slidesToShow:
                        integrations.length >= 3 ? 3 : integrations.length,
                    variableWidth: integrations.length <= 3,
                },
            },
            {
                breakpoint: marketplaceSettings?.fullWidthMarketplace
                    ? 1050
                    : 1280,
                settings: {
                    slidesToShow:
                        integrations.length >= 2 ? 2 : integrations.length,
                    variableWidth: integrations.length <= 2,
                },
            },
            {
                breakpoint: marketplaceSettings?.fullWidthMarketplace
                    ? 750
                    : 960,
                settings: {
                    slidesToShow:
                        integrations.length >= 1 ? 1 : integrations.length,
                    variableWidth: integrations.length <= 1,
                },
            },
        ],
    }

    return (
        <Box
            sx={{
                padding: '40px 40px 80px',
                '.MuiGrid-item': {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '20px',
                },
                // Remove default arrows https://github.com/akiran/react-slick/issues/491
                '.slick-next::before, .slick-prev::before': {
                    content: 'none',
                },
                '.slick-track': {
                    display: 'flex',
                    justifyContent: 'center',
                },
                width: width,
            }}
        >
            <Typography
                sx={{
                    lineHeight: '48px',
                    fontSize: '40px',
                    textAlign: 'center',
                    paddingBottom: '40px',
                }}
            >
                {sliderTitle}
            </Typography>
            <Slider {...settings}>
                {integrations.map((integration) => (
                    <IntegrationCard
                        key={integration.id}
                        integration={integration}
                    />
                ))}
            </Slider>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(IntegrationCarousel)
