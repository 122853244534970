import React, { Component } from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { isSameDay } from 'date-fns'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { FormControlLabel, FormLabel, Switch, TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { isEqual } from 'lodash-es'

const dateStyles = (theme) => ({
    checkbox: {
        margin: '15px 5px 0 0',
    },
    datePicker: {
        margin: '7px 0 0 5px',
        width: '190px',
    },
    switchBase: {
        color: 'white',
        '&.Mui-checked': {
            color: theme.palette.primary.main,
        },
        '&.Mui-checked + &.Mui-track': {
            color: theme.palette.primary.main,
        },
    },
    checked: {
        color: theme.palette.primary.main + ' !important',
    },
    track: {
        backgroundColor: theme.palette.primary.main + ' !important',
    },
})

/**
 * Date picker component with integrated check box that sets value to "1970-01-01T00:00:00.000Z" when checked
 * uiType must be 'date' in the json schema for this component to render
 */
class MaterialDateField extends Component {
    state = {
        checked: false,
    }

    handleCheckbox = (stateName) => (ev) => {
        this.setState({ [stateName]: ev.target.checked })
    }

    componentDidMount() {
        if (isSameDay(Date.parse(this.props.data), new Date(0))) {
            this.setState({ checked: true })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Connect date to relevant checked state
        if (!isEqual(prevProps.data, this.props.data)) {
            if (isSameDay(Date.parse(this.props.data), new Date(0))) {
                this.setState({ checked: true })
            } else {
                this.setState({ checked: false })
            }
        }

        // Connect checked state to relevant date
        if (!isEqual(prevState.checked, this.state.checked)) {
            if (this.state.checked) {
                this.props.handleChange(
                    this.props.path,
                    new Date(0).toISOString()
                )
            } else {
                this.props.handleChange(
                    this.props.path,
                    new Date().toISOString()
                )
            }
        }
    }

    render() {
        const { data, handleChange, path, label, classes } = this.props
        const { checked } = this.state
        return (
            <div>
                <FormLabel component="legend"> {label} </FormLabel>
                <FormControlLabel
                    className={classes.checkbox}
                    label={!checked ? 'Sync since' : 'All time'}
                    control={
                        <Switch
                            checked={checked}
                            onChange={this.handleCheckbox('checked')}
                            value="checked"
                            classes={{
                                switchBase: classes.switchBase,
                                checked: classes.checked,
                                track: classes.track,
                            }}
                        />
                    }
                />
                {!checked && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            onChange={(ev) => {
                                handleChange(path, ev.toISOString())
                            }}
                            value={data || null}
                            format="MM/dd/yyyy"
                            disableFuture
                            disabled={checked}
                            className={classes.datePicker}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                )}
            </div>
        )
    }
}

export default withStyles(dateStyles)(
    withJsonFormsControlProps(MaterialDateField)
)
