import React, { useEffect, useState, createContext, useContext } from 'react'
import { JsonForms } from '@jsonforms/react'
import $RefParser from '@apidevtools/json-schema-ref-parser'
import { useTheme, StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { materialCells, materialRenderers } from '@jsonforms/material-renderers'
import { createAjv } from '@jsonforms/core'
import { get, merge } from 'lodash-es'
import CustomEnumControl from './CustomEnumControl'
import CustomEnumControlTester from './CustomEnumControlTester'
import CustomLabel from './CustomLabel'
import CustomLabelTester from './CustomLabelTester'
import Divider from './Divider'
import DividerTester from './DividerTester'
import MultipleSelectFieldControl from './MultipleSelectFieldControl'
import MultipleSelectFieldControlTester from './MultipleSelectFieldControlTester'
import FileInputControl from './FileInputControl'
import FileInputControlTester from './FileInputControlTester'
import SubtitleControl from './Subtitle'
import SubtitleControlTester from './SubtitleTester'
import DateControl from './DateControl'
import DateControlTester from './DateControlTester'
import AdvancedMapperTester from './AdvancedMapperTester'
import { AdvancedMapperControl } from '@thefront/pandipackV2'
import SectionLayout, { sectionTester } from './SectionLayout'
import DynamicOneOfControl, {
    dynamicOneOfControlTester,
} from './DynamicOneOfControl'
import DependentSelectControl from './DependentSelectControl'
import { DependentSelectControlTester } from './DependentSelectControl'

const DynamicConfigContext = createContext()
const DynamicConfigContextProvider = ({ value, children }) => (
    <DynamicConfigContext.Provider value={value}>
        {children}
    </DynamicConfigContext.Provider>
)
export const useDynamicConfigs = () => useContext(DynamicConfigContext)

/**
 * JsonForms is an isolated component without global state. This helps us manage multiple forms easier.
 * https://jsonforms.io/docs/react#json-forms-as-a-standalone-component
 * @param onJsonFormChange -- Pass a set state callback to get data from this component to the parent
 * @param schema
 * @param uischema
 * @param data - the data loaded into jsonforms, ie: config values
 * @param fullWidth
 * @param readonly - set as true to disable an etire form. Defaults to false.
 * @returns {*}
 */
export const JsonFormsWrapper = React.memo(
    ({
        schema,
        uischema,
        onJsonFormChange,
        data,
        fullWidth,
        readonly = false,
        dynamicConfigs = {},
    }) => {
        const updateData = (errors, data) => {
            onJsonFormChange(errors, data)
        }

        // resolves refs. this is needed for the advanced mapper.
        const [resolvedSchema, setSchema] = useState()
        useEffect(() => {
            $RefParser.dereference(schema).then((res) => setSchema(res))
        }, [schema])

        const ajv = createAjv({ useDefaults: true, coerceTypes: true })

        /**
         * custom AJV keyword `requireNotEmpty` will reject blank input fields (i.e, spaces and empty inputs).
         *
         * In an example schema:
         *
         * schema: {
         *     definitions: { ... },
         *     name: "..."
         *     properties: {
         *        field_one: { type: "boolean", default: true }
         *        field_two: { type: "array", items: { ... } }
         *        optional_field: { type: "string" }
         *        another_field: { type: "string", requireNotEmpty: true }
         *        ...
         *     },
         *     required: ["another_field"],
         *     type: "object"
         * }
         *
         * the `another_field` will now be validated against the `requireNotEmpty`
         * validation function
         */
        ajv.addKeyword('requireNotEmpty', {
            type: 'string',
            validate: function validate(resolvedSchema, data) {
                validate.errors = [
                    {
                        keyword: 'requireNotEmpty',
                        message: 'Field must not be empty.',
                        params: { keyword: 'requireNotEmpty' },
                    },
                ]
                return data.trim() !== ''
            },
            errors: true,
        })

        // Mutates incoming schema to attach stronger validation on all `required` text input fields.
        // JsonFormsWrapper's parent component may be memoized, so keeping this logic out of `useEffect`
        // ensures this component reads the correct updated schema.
        if (get(resolvedSchema, 'required')) {
            resolvedSchema.required.forEach((item) => {
                const schemaPropItem = get(resolvedSchema, `properties.${item}`)
                if (
                    schemaPropItem &&
                    schemaPropItem.type === 'string' &&
                    get(schemaPropItem, 'requireNotEmpty') === undefined
                ) {
                    merge(resolvedSchema.properties[item], {
                        requireNotEmpty: true,
                    })
                }
            })
        }

        useEffect(() => {
            if (!fullWidth) {
                uischema.elements.forEach((element, idx) => {
                    if (
                        element.type === 'Control' &&
                        get(element, 'options.trim') === undefined
                    ) {
                        const matchingSchema = get(
                            resolvedSchema,
                            'properties.' + element.scope.split('/')[2]
                        )

                        if (get(matchingSchema, 'type') === 'string') {
                            merge(uischema.elements[idx], {
                                options: { trim: true },
                            })
                        }
                    }
                })
            }
        }, [fullWidth, uischema, resolvedSchema])

        const mainTheme = useTheme()

        const jsonFormTheme = {
            overrides: {
                MuiFormControl: {
                    root: {
                        minWidth: '15em',
                        marginBottom: '10px',
                    },
                },
                MuiInputLabel: {
                    root: {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '100%',
                        whiteSpace: 'nowrap',
                    },
                },
            },
            jsonforms: {
                input: {
                    delete: {
                        background: '#ffffff',
                    },
                },
            },
        }

        const newTheme = {}
        Object.assign(newTheme, mainTheme, jsonFormTheme)

        return (
            resolvedSchema && (
                <DynamicConfigContextProvider value={dynamicConfigs}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={newTheme}>
                            {ajv.validateSchema(resolvedSchema) ? (
                                <JsonForms
                                    data={data}
                                    schema={resolvedSchema}
                                    uischema={uischema}
                                    readonly={readonly}
                                    renderers={[
                                        ...materialRenderers,
                                        {
                                            tester: CustomEnumControlTester,
                                            renderer: CustomEnumControl,
                                        },
                                        {
                                            tester: sectionTester,
                                            renderer: SectionLayout,
                                        },
                                        {
                                            tester: CustomLabelTester,
                                            renderer: CustomLabel,
                                        },
                                        {
                                            tester: DividerTester,
                                            renderer: Divider,
                                        },
                                        {
                                            tester: MultipleSelectFieldControlTester,
                                            renderer: MultipleSelectFieldControl,
                                        },
                                        {
                                            tester: FileInputControlTester,
                                            renderer: FileInputControl,
                                        },
                                        {
                                            tester: SubtitleControlTester,
                                            renderer: SubtitleControl,
                                        },
                                        {
                                            tester: DateControlTester,
                                            renderer: DateControl,
                                        },
                                        {
                                            tester: AdvancedMapperTester,
                                            renderer: AdvancedMapperControl,
                                        },
                                        {
                                            tester: dynamicOneOfControlTester,
                                            renderer: DynamicOneOfControl,
                                        },
                                        {
                                            tester: DependentSelectControlTester,
                                            renderer: DependentSelectControl,
                                        },
                                    ]}
                                    cells={materialCells}
                                    onChange={({ errors, data }) =>
                                        updateData(errors, data)
                                    }
                                    ajv={ajv}
                                />
                            ) : (
                                <p>
                                    There is an error within your configs.
                                    Please contact an admin to fix this.
                                </p>
                            )}
                        </ThemeProvider>
                    </StyledEngineProvider>
                </DynamicConfigContextProvider>
            )
        )
    },
    // The JsonForm component handles updating data internally, so the wrapper can get into an update loop if it is rerendered on every formData change
    (prevProps, nextProps) =>
        !Object.keys(prevProps).some(
            (propName) =>
                propName !== 'formData' &&
                JSON.stringify(prevProps[propName]) !==
                    JSON.stringify(nextProps[propName])
        )
)

JsonFormsWrapper.defaultProps = {
    data: {},
}
