/* Custom Input Validators */
import React, { useEffect, useRef } from 'react'
import { useGetMany } from 'react-admin'
import { CardMedia, Box } from '@mui/material'
import { isEmpty, get, remove, endsWith } from 'lodash-es'

/**
 * Parses tenant's ui-schema and hides admin only fields
 * @param uischema
 * @returns {parsed uischema}
 */
export const filterUiSchema = (uischema) => {
    !isEmpty(get(uischema, 'elements')) &&
        remove(uischema.elements, function (element) {
            return element.admin === true
        })

    return uischema
}

export const isLowerCase = (str) => {
    if (str && str.toLowerCase() !== str) {
        return 'Must be all lowercase'
    }
}

export const getBasePath = (location) => {
    return location.pathname.split('/').slice(0, -2).join('/')
}

export const isConnectorConnected = (tenant, connectorName) => {
    return (
        get(
            tenant,
            `status.auth.${connectorName.toUpperCase()}_AUTH_STATUS`
        ) === 'Connected'
    )
}

// Hook to get connectors
export const useConnectors = (resource, integration = false) => {
    const connectorList =
        integration === true
            ? get(resource, 'connectors')
            : get(resource, 'integration.connectors')

    // check if null before mapping
    const connectorIds = isEmpty(connectorList)
        ? []
        : connectorList.map((connector) => {
              return connector.name
          })
    const { data, isLoading, error } = useGetMany('connectors', {
        ids: connectorIds,
    })

    if (error) {
        console.debug('Error fetching connectors: ', error)
        // TODO what would we like to display in case of fetching errors? Can be reused across the product
        return null
    }

    //TODO: revisit this with react-admin upgrade, this seems fixed in v4
    return !isLoading && !data.includes(undefined) ? data : []
}

export const SCHEDULE = [
    { id: '', name: 'Select option' },
    { id: '*/30 * * * *', name: 'Every 30 minutes' },
    { id: '5 */1 * * *', name: 'Every 1 Hour' },
    { id: '10 */4 * * *', name: 'Every 4 Hours' },
    { id: '15 */6 * * *', name: 'Every 6 Hours' },
    { id: '20 */12 * * *', name: 'Every 12 Hours' },
    { id: '0 1 * * *', name: 'Once per day' },
]

export const getConnectorHelpText = (tenant, connectors) => {
    let connectorHelpText = ''
    const tenantConnectors = get(tenant, 'integration.connectors', [])

    !isEmpty(tenantConnectors) &&
        tenantConnectors.forEach((connector) => {
            if (!connector.isGlobal) {
                return (connectorHelpText = `${connectorHelpText} ${get(
                    connectors,
                    `${connector.name}.label`,
                    connector.name
                )} 
                ${get(
                    connectors,
                    `${connector.name}.singularAccountNoun`,
                    'account'
                )} and`)
            }
        })
    if (endsWith(connectorHelpText, ' and')) {
        connectorHelpText = connectorHelpText.substring(
            0,
            connectorHelpText.length - 4
        )
    }

    return connectorHelpText
}

export const CustomImage = ({ src, sx }) => {
    return (
        <Box
            component="img"
            src={src}
            alt={src}
            sx={sx}
            onError={(ev) => {
                ev.target.style = 'display:none'
            }}
        />
    )
}

export const CustomCardMedia = ({ src, className }) => {
    return (
        <CardMedia
            className={className}
            src={src}
            component="img"
            onError={(ev) => {
                ev.target.style = 'display:none'
            }}
        />
    )
}

export const cleanUserName = (userName) => {
    return userName
        ? userName.replace('+', '-').replace('@', '-').replace('.', '-')
        : ''
}

// custom hook for getting previous value
export function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const isBackgroundConnect = (connector, theme, connectors) => {
    const fullConnector = connectors.find(({ name }) => name === connector.name)
    const basename = window.location.pathname.split('/')[1]
    return (
        get(
            theme,
            'configs.marketplaceSettings.storeBackgroundConnect',
            false
        ) &&
        connector.name.includes(basename) &&
        (get(fullConnector, 'metadata.grant_flow') === 'custom-sso' ||
            (get(fullConnector, 'metadata.grant_flow') === 'backend' &&
                get(fullConnector, 'backgroundConnect.client') === 'frontegg'))
    )
}
