import React from 'react'
//Image License as of 07/29/2019
//https://pixabay.com/vectors/pixel-cells-problem-technology-3974187/
import Broken from './broken.png'
import { Typography, Button, Box } from '@mui/material'

class ErrorPage extends React.PureComponent {
    render() {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    backgroundColor: '#7373c4',
                    minWidth: '1000px',
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        bottom: '100px',
                        margin: '0 75px',
                    }}
                >
                    <Typography
                        variant="h2"
                        margin="20px 0"
                        align="center"
                        color="white"
                    >
                        {' '}
                        Something went wrong!
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="white"
                        fontSize="1.2rem"
                    >
                        {' '}
                        Sorry, it&apos;s me, not you...
                    </Typography>
                    <Box
                        sx={{
                            margin: '15px 0',
                            display: 'flex',
                            gap: '12px',
                        }}
                    >
                        <Button
                            href="/"
                            sx={{
                                color: 'white',
                                fontSize: '1.2rem',
                                border: '1px solid white',
                                width: '120px',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: '#7373c4',
                                },
                            }}
                        >
                            Home
                        </Button>
                        <Button
                            onClick={() => window.location.reload()}
                            sx={{
                                color: 'white',
                                fontSize: '1.2rem',
                                border: '1px solid white',
                                width: '120px',
                                '&:hover': {
                                    backgroundColor: 'white',
                                    color: '#7373c4',
                                },
                            }}
                        >
                            Refresh
                        </Button>
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        bottom: '100px',
                        margin: '0 75px',
                    }}
                >
                    <img
                        style={{
                            height: '400px',
                            width: 'auto',
                        }}
                        src={Broken}
                        alt="error"
                    />
                </Box>
            </Box>
        )
    }
}

export default ErrorPage
