import React from 'react'
import { useSelector } from 'react-redux'
import { IntegrationCard as PandipackCard } from '@thefront/pandipackV2'
import { useRedirect } from 'react-admin'
import { useTheme } from '@mui/material/styles'
import { useThemeConfig } from '../../themeConfig'

export const IntegrationCard = ({ integration }) => {
    const redirect = useRedirect()
    const navigate = () => redirect(`/tenants/create/${integration.id}`)
    const user = useSelector((state) => state.user)
    const muiTheme = useTheme()
    const theme = useThemeConfig()
    const { marketplaceSettings } = theme.configs

    return (
        <PandipackCard
            integration={integration}
            user={user}
            navigate={navigate}
            marketplaceSettings={marketplaceSettings}
            muiTheme={muiTheme}
        />
    )
}
