import React from 'react'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { get, sortBy } from 'lodash-es'
import { connect } from 'react-redux'
import { useThemeConfig } from '../../themeConfig'
import useMediaQuery from '@mui/material/useMediaQuery'
import { IntegrationCard } from './integrationCardUtils'

export const ConnectGrid = ({ searchView, integrations, user, ...rest }) => {
    const theme = useThemeConfig()

    // Theme settings have two ways of ordering `orderIndex` and `alphabetical`.
    const sortPropForOrder = {
        orderIndex: 'marketplaceSettings.orderIndex',
        alphabetical: 'long_name',
    }
    const sortOrder = get(theme, 'configs.sortOrder')
    const sortProps = sortOrder ? [sortPropForOrder[sortOrder], ['id']] : ['id']
    const sortedIntegrations =
        sortOrder === 'alphabetical'
            ? sortBy(
                  integrations,
                  //TODO: fix the fact that we have both cases loaded...bad bad bad SHAME
                  [
                      (integration) =>
                          integration.long_name?.toLowerCase() ??
                          integration.longName?.toLowerCase(),
                  ],
                  sortProps
              )
            : sortBy(integrations, sortProps)
    const muiTheme = useTheme()
    const isLargeMedia = useMediaQuery(muiTheme.breakpoints.up('lg'))
    return (
        <>
            <Grid
                container
                spacing={isLargeMedia ? 4 : 2}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginTop: '40px' }}
            >
                {sortedIntegrations.map((integration) => (
                    <IntegrationCard
                        key={integration.id}
                        integration={integration}
                    />
                ))}
            </Grid>
            {!integrations.length > 0 && searchView && (
                <div>
                    {theme.configs.marketplaceSettings
                        .integrationNullSearchMessage ??
                        "Sorry, we couldn't find the result you’re looking for. \nPlease try again."}
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(ConnectGrid)
