import React from 'react'
import {
    withJsonFormsOneOfEnumProps,
    withTranslateProps,
} from '@jsonforms/react'
import { Autocomplete, TextField, FormHelperText } from '@mui/material'
import { rankWith, and, schemaMatches, uiTypeIs } from '@jsonforms/core'
import { useDynamicConfigs } from './JsonFormsWrapper'

export const dynamicOneOfControlTester = rankWith(
    Number.MAX_VALUE,
    and(
        uiTypeIs('Control'),
        // schema for dynamic oneOf compoents is overwritten to match this
        // pattern in getDynamicConfigSchema in pandipackV2
        schemaMatches((schema) => schema.hasOwnProperty('dynamicOneOfKey'))
    )
)

// Borrowed liberally from JSONforms' MaterialOnOfEnumControl renderer
function DynamicOneOfControl({
    path,
    handleChange,
    label,
    data,
    required,
    schema,
    errors,
}) {
    const dynamicConfigs = useDynamicConfigs()

    const isValid = errors.length === 0
    const options = dynamicConfigs?.[schema.dynamicOneOfKey]

    const [inputValue, setInputValue] = React.useState(data ?? '')
    const findOption = options.find((o) => o.const === data) ?? null

    return (
        <>
            <Autocomplete
                disablePortal
                id={path}
                options={
                    options || [{ const: 'placeholder', title: 'Placeholder' }]
                }
                value={findOption}
                inputValue={inputValue}
                onChange={(_event, newValue) => {
                    handleChange(path, newValue?.const)
                }}
                onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="standard"
                        required={required}
                        error={!isValid}
                    />
                )}
                getOptionLabel={(option) => option.title || ''}
            />
            {!isValid && <FormHelperText error={true}>{errors}</FormHelperText>}
        </>
    )
}

export default withJsonFormsOneOfEnumProps(
    withTranslateProps(React.memo(DynamicOneOfControl))
)
