import React, { useState } from 'react'
import { Input } from '@mui/material'
import elasticlunr from 'elasticlunr'
import SearchIcon from '@mui/icons-material/Search'
import { get } from 'lodash-es'
import { useThemeConfig } from '../themeConfig'
import { styled } from '@mui/system'

const StyledForm = styled('form')(({ theme, isTenantListPage, focused }) => ({
    backgroundColor: '#f4f4f4',
    paddingLeft: '16px',
    marginBottom: '20px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: '56px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    ...(isTenantListPage ? { marginTop: '30px' } : {}),

    ...(isTenantListPage && focused
        ? { borderBottom: `2px solid ${theme.palette.primary.main}` }
        : {}),
}))

// Global search index
let searchIndex

//generate the index
export const genSearchIndex = (integrations) => {
    elasticlunr.clearStopWords()
    searchIndex = elasticlunr(function () {
        this.addField('longName')
        this.addField('categories')
        this.addField('tags')
        this.setRef('id')
        /* For expanding search features : add more fields here */
    })
    for (let id in integrations) {
        let doc = {
            id: integrations[id].id,
            longName: integrations[id].longName,
            tags: integrations[id].tags?.map((tag) => tag.name),
            categories: integrations[id].categories?.map((cat) => cat.name),
            /* For expanding search features : add more fields here */
        }
        searchIndex.addDoc(doc)
    }
}

export const genTenantSearchIndex = (tenants) => {
    elasticlunr.clearStopWords()
    searchIndex = elasticlunr(function () {
        this.addField('longName')
        this.addField('tenantName')
        this.addField('categories')
        this.addField('tags')
        this.setRef('id')
        /* For expanding search features : add more fields here */
    })
    tenants.forEach((tenant) => {
        const splitName = tenant.name.split('--')
        const tenantName = splitName[splitName.length - 1]
        if (tenant.integration) {
            // handle cases where api returns tenants with deleted integrations
            let doc = {
                id: tenant.id.toString(),
                tenantName: tenantName,
                longName: tenant.integration.long_name,
                tags: tenant.integration.tags?.map((tag) => tag.name),
                categories: tenant.integration.categories?.map(
                    (cat) => cat.name
                ),
                /* For expanding search features : add more fields here */
            }
            searchIndex.addDoc(doc)
        } else if (tenant.type === 'External') {
            // the "tenant" in this case is actually an external integration
            // that we want to show up as a tenant card because the customer
            // has commuinicated via the JWT that this user has this
            // external integration installed.
            let doc = {
                // just in case we run into a situation where the integraion
                // id is the same as one of the tenant ids
                id: 'external-' + tenant.id.toString(),
                tenantName: tenant.longName,
                longName: tenant.longName,
                tags: tenant.tags?.map((tag) => tag.name),
                categories: tenant.categories?.map((cat) => cat.name),
            }
            searchIndex.addDoc(doc)
        }
    })
}

const search = (searchTerm) => {
    const searchResult = searchIndex.search(searchTerm, {
        expand: true,
        bool: 'AND',
    })
    //building an array of searched id
    return searchResult.map(
        (elem) => searchIndex.documentStore.getDoc(elem.ref).id
    )
}

export const SearchBar = ({
    isTenantListPage = false,
    handler,
    clear,
    deepLinkIntegration = '',
    placeholder,
}) => {
    const pandiumTheme = useThemeConfig()
    const [value, setValue] = useState(deepLinkIntegration)
    const [focused, setFocused] = useState(false)

    const handleChange = (event) => {
        const newValue = event.target.value
        setValue(newValue)
        if (!newValue) {
            clear()
        } else {
            let searchResults = search(newValue, { bool: 'AND' })
            handler(searchResults)
        }
    }

    return (
        <StyledForm
            onSubmit={(e) => e.preventDefault()}
            className={`pandium-search-bar`}
            isTenantListPage={isTenantListPage}
            focused={focused}
        >
            <SearchIcon
                sx={(theme) => ({
                    color: get(
                        pandiumTheme,
                        'searchIcon.color',
                        get(theme, 'palette.primary.main', 'black')
                    ),
                    padding: '4px',
                })}
            />
            <Input
                placeholder={placeholder}
                value={value}
                type="input"
                disableUnderline={true}
                onChange={handleChange}
                fullWidth={true}
                onFocus={() => {
                    setFocused(true)
                }}
                onBlur={() => {
                    setFocused(false)
                }}
            />
        </StyledForm>
    )
}
