import React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { useThemeConfig } from '../../themeConfig'
import { IntegrationCard } from './integrationCardUtils'
import { KeyboardArrowRight } from '@mui/icons-material'
import { connect } from 'react-redux'

const IntegrationByCategory = ({ category, setSelectedCategory, user }) => {
    const theme = useThemeConfig()
    const { name, integrations } = category
    const maxCardCount =
        theme?.configs?.marketplaceSettings?.categoryTileCount || 3
    const cardCount =
        integrations?.length < maxCardCount ? integrations.length : maxCardCount
    return integrations && cardCount ? (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    lineHeight: '25px',
                    padding: '25px 0px',
                    marginTop: '30px',
                }}
            >
                <Typography
                    sx={{
                        color: theme.config?.palette.primary.main,
                        fontSize: '20px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                    }}
                    className={'pandium-category-name'}
                >
                    {name.includes('--') ? name.split('--')[1] : name}
                </Typography>
                <Typography
                    className={'pandium-see-all-button'}
                    role="button"
                    sx={{
                        color: theme.config?.palette.primary.main,
                        textTransform: 'uppercase',
                        '&.selected': {
                            fontWeight: 600,
                        },
                        '&:hover, &.selected': {
                            filter: 'brightness(50%)',
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => {
                        setSelectedCategory(name)
                    }}
                >
                    See all
                    <KeyboardArrowRight sx={{ verticalAlign: 'top' }} />
                </Typography>
            </Box>
            <Grid container spacing={[2, 2, 2, 2, 4, 4]}>
                {integrations.slice(0, cardCount).map((integration) => (
                    <IntegrationCard
                        key={integration.id}
                        integration={integration}
                    />
                ))}
            </Grid>
        </>
    ) : null
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(mapStateToProps)(IntegrationByCategory)
