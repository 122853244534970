import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LogField } from '../../components/LogField'
import { API_URI, HEADERS } from '../../themeConfig'
import { GetApp, Refresh } from '@mui/icons-material'

const useRunActionStyles = makeStyles((theme) => {
    return {
        root: {
            margin: '10px',
            '& > div': {
                boxShadow: 'none',
            },
        },
        actionsDisplay: {
            margin: '20px 0 0',
            display: 'flex',
            width: '100%',
            padding: '0 !important',
            justifyContent: 'flex-end',
        },
        actionButtons: {
            padding: '10px',
            marginLeft: '10px',
            fontSize: '1rem',
        },
    }
})

const RunExpand = (props) => {
    const { record, namespace } = props
    const classes = useRunActionStyles()
    const [refreshSyncLog, setRefreshSyncLog] = useState(0)

    const refresher = () => {
        //refreshSyncLog is a key in the LogField component, so incrementing it forces the LogField component to remount.
        //The remount triggers an API call to fetch the log data
        setRefreshSyncLog(refreshSyncLog + 1)
    }

    const downloader = () => {
        const token = sessionStorage.getItem('token')
        fetch(`${API_URI}/runs/${record.id}/log`, {
            method: 'GET',
            headers: HEADERS(token),
        })
            .then((data) => {
                return data.blob()
            })
            .then((blob) => {
                //unless we use a library this is general advice for downloading data from api call
                //https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
                const element = document.createElement('a')
                element.href = URL.createObjectURL(blob)
                element.download = `${record.name}.txt`
                document.body.appendChild(element) // Required for this to work in FireFox
                element.click()
            })
            .catch((err) => console.error(err))
    }

    return (
        <div className={classes.root}>
            <LogField
                key={refreshSyncLog}
                source="id"
                namespace={namespace}
                record={record}
            />
            <div className={classes.actionsDisplay}>
                <div>
                    <Button
                        startIcon={<Refresh />}
                        variant={'outlined'}
                        label={'REFRESH'}
                        className={classes.actionButtons}
                        color="primary"
                        onClick={refresher}
                    >
                        REFRESH
                    </Button>
                    <Button
                        startIcon={<GetApp />}
                        variant={'outlined'}
                        label={'DOWNLOAD'}
                        className={classes.actionButtons}
                        color="primary"
                        onClick={downloader}
                    >
                        {' '}
                        DOWNLOAD
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default (props) => {
    const { namespace, record, setExpand } = props
    //not using builtin datagrid button which did this automatically
    useEffect(() => {
        setExpand(true)
        return function cleanup() {
            setExpand(false)
        }
    })

    return record && <RunExpand namespace={namespace} record={record} />
}
