import React from 'react'
import { Typography } from '@mui/material'
import { Help } from '@mui/icons-material'
import { get } from 'lodash-es'
import RichTooltip from './RichTooltip'

const labelStyles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: '#6d6d6d',
        fontSize: '21px',
        alignItems: 'center',
        display: 'flex',
    },
    subtitle: {
        display: 'block',
        margin: '10px 0 0px 0px',
        fontSize: '14px',
    },
    muiTitle: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '1',
        display: 'flex',
        alignItems: 'center',
    },
    toolTipsIcon: (theme) => ({
        color: get(theme, 'toolTips.iconColor'),
        height: '20px',
        width: '20px',
        alignItems: 'center',
        margin: '0 0 0 5px',
    }),
}

export default ({ uischema }) => {
    return uischema.title && !uischema.subtitle ? (
        <div style={labelStyles.root}>
            <Typography component="label" sx={labelStyles.muiTitle}>
                {' '}
                {uischema.title}{' '}
                {uischema.hintText && (
                    <RichTooltip title={uischema.hintText}>
                        <Help sx={labelStyles.toolTipsIcon} />
                    </RichTooltip>
                )}
            </Typography>
        </div>
    ) : (
        <div style={labelStyles.root}>
            {uischema.title && (
                <Typography component="label" sx={labelStyles.title}>
                    {' '}
                    {uischema.title}{' '}
                    {uischema.hintText && (
                        <RichTooltip title={uischema.hintText}>
                            <Help sx={labelStyles.toolTipsIcon} />
                        </RichTooltip>
                    )}
                </Typography>
            )}
            {uischema.subtitle && (
                <Typography component="label" sx={labelStyles.subtitle}>
                    {' '}
                    {uischema.subtitle}{' '}
                </Typography>
            )}
        </div>
    )
}
